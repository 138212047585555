<template>
  <img
    id="whatsapp_icon"
    src="/images/ui/whatsapp.png"
    @click="onWhatsAppClick()"
  />
</template>

<script>
import { config } from "../helpers/domain-config";

export default {
  methods: {
    onWhatsAppClick() {
      const whatsAppLink = config.getWhatsAppLink().whatsAppLink;
      window.open(whatsAppLink, "_blank");
    },
  },
};
</script>
