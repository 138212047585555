<template>
  <div class="modal_section" :class="status" v-if="isModalOpen">
    <div @click="onModalClose" class="close_modal_btn">
      <img src="/images/ui/exit-button.png" />
    </div>
    <div class="modal_content">
      <p v-html="modalText"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isModalOpen: { type: Boolean },
    status: { type: String },
    onModalClose: { type: Function },
    modalText: { type: String },
  },
};
</script>

<style scoped lang="scss">
.modal_section {
  position: relative;
  max-width: 400px;
  border-radius: 8px;
  padding: 20px;
  margin: 0 10px;

  &.success {
    background-color: green;
  }
  &.fail {
    background-color: red;
  }
  .close_modal_btn {
    img {
      width: 21px;
      position: absolute;
      right: 1%;
      top: 4%;
      cursor: pointer;
    }
  }
  .modal_content {
    p {
      font-size: 14px;
      line-height: 1.5;
      font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
      text-align: center;
      color: #fff;
    }
  }
}
</style>
