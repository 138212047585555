import {
  domainConfig,
  loginPrimeHash,
  PRODUCT_XR_ACADEMY,
  PRODUCT_XRACADEMY,
  PRODUCT_MOBIO_VR,
  restrictedSubDomains,
} from "./page-constants";
import { environmentConfig } from "../../environments/environment";

export const getSubdomain = () => {
  const host = window.location.hostname;
  return host.split(".")[0];
};

export const checkIfRestrictedSubDomain = () => {
  const subdomain = getSubdomain();
  return restrictedSubDomains.includes(subdomain);
};

const getProductName = () => {
  const host = window.location.hostname;
  if (host.split(".")[1] === PRODUCT_MOBIO_VR) return PRODUCT_MOBIO_VR;
  if (host.split(".")[1] === PRODUCT_XRACADEMY) return PRODUCT_XRACADEMY;
  return PRODUCT_XR_ACADEMY;
};

const getCustomerCareInfo = () => {
  const subdomain = getSubdomain();
  const showCustomerCareLink =
    subdomain === "de" ||
    subdomain === "kw" ||
    subdomain === "om" ||
    subdomain === "ae" ||
    subdomain === "nl" ||
    subdomain === "ae" ||
    subdomain === "iq" ||
    subdomain === "ps" ||
    subdomain === "bh" ||
    subdomain === "pk" ||
    subdomain === "my";
  const customerCareLink = `https://${getProductName()}.com/customer-care?subdomain=${subdomain}`;
  return { showCustomerCareLink, customerCareLink };
};
const getWhatsAppLink = () => {
  /* eslint-disable max-len */
  const subdomain = getSubdomain();
  const showWhatsAppLink = subdomain === "my";
  const whatsAppLink =
    "https://api.whatsapp.com/send/?phone=601111673277&text=Hi%2C+I+am+contacting+from+your+checksubscription+platform.+Could+you+please+help+me+with+my+subscription&type=phone_number&app_absent=0";
  return { showWhatsAppLink, whatsAppLink };
};

const getHash = (subdomain, productName, topLevelDomain) => {
  const host = `${subdomain}.${productName}.${topLevelDomain}`;
  const idx = loginPrimeHash.findIndex((item) => item.domain === host);
  return loginPrimeHash[idx].hash;
};
const getImpressumLink = () => {
  const subdomain = getSubdomain();
  const showImpressum = subdomain === "de";
  const impressumLink = "https://sam-media.com/impressum/";
  return { showImpressum, impressumLink };
};

const getTopLevelDomain = () => {
  const host = window.location.hostname;
  return host.split(".")[2] || "com";
};

const setVrButtonPosLeft = () => {
  const subdomain = getSubdomain();
  const host = window.location.hostname;
  const isLeftVRButton =
    subdomain === "my" ||
    subdomain === "sg" ||
    subdomain === "portal" ||
    (host.includes(PRODUCT_MOBIO_VR) && subdomain === "ae") ||
    (host.includes(PRODUCT_XR_ACADEMY) && subdomain === "ch");
  return isLeftVRButton;
};
export const disableQuizzSubDomains = [
  "fr",
  "cz",
  "se",
  "pl",
  "ch",
  "id",
  "de",
  "portal",
  "th",
  "my",
];

export const checkIsDisableQuizzSubDomains = () => {
  const subdomain = getSubdomain();
  return disableQuizzSubDomains.includes(subdomain);
};

const getConfigs = (subdomain, product, passedTopLevelDomain) => {
  // Default Config in case it is not found
  let config = domainConfig["demo"];
  const subdomains = Object.keys(domainConfig);
  const productName = product || getProductName();
  const topLevelDomain = passedTopLevelDomain || getTopLevelDomain();
  let hash = getHash("demo", PRODUCT_XR_ACADEMY, "com");

  if (subdomains.indexOf(subdomain) >= 0) {
    config = domainConfig[subdomain];
    hash = getHash(subdomain, productName, topLevelDomain);
  }

  const pageConfigs = {
    ...config,
    topLevelDomain,
    productName,
    subdomain,
    hash,
    getCustomerCareInfo,
    getWhatsAppLink,
    setVrButtonPosLeft,
    getImpressumLink,
  };
  return pageConfigs;
};

export let config = {};

if (getSubdomain() === "localhost" || environmentConfig.production === false)
  config = getConfigs("umobile", PRODUCT_XR_ACADEMY, "com");
// set here for localhost
else config = getConfigs(getSubdomain());

export const getCategoryFromUrl = () => {
  const url = window.location.href;
  const category = url.split("/")[3].split("?")[0];
  return category;
};
