<template>
  <div id="refund-policy">
    <div v-if="refundData !== null">
      <div class="refund-container">
        <h1>{{ refundData.title }}</h1>
        <div class="refund_details">
          <p>{{ refundData.text1 }}</p>
          <p>{{ refundData.text2 }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { waitForLoaderToHide } from "../../aframe/utils/scene.utils";
import { getRefundPolicyText } from "../helpers/refund-page.data.helper";

export default {
  async created() {
    waitForLoaderToHide();
    this.refundData = await getRefundPolicyText();
  },
  async mounted() {
    this.refundData = await getRefundPolicyText();
  },
  data: () => ({
    refundData: null,
  }),
};
</script>

<style lang="scss">
#refund-policy {
  background-color: #efefef;
  cursor: auto;
  padding: 25px 30px;
  color: #3a3a3a;
  font-family: "Helvetica Neue";
  height: 100%;

  .refund-container {
    margin-top: 5%;
    h1 {
      font-size: 20px;
      font-weight: normal;
      line-height: 23px;
      margin-bottom: 5px;
      color: #3f3f3f;
      text-align: center;
    }
  }
  .refund_details {
    padding: 2%;
    p {
      font-size: 15px;
      font-weight: normal;
      line-height: 19px;
    }
  }
  @media screen and (max-width: 800px) {
    .refund-container {
      margin-top: 20%;
    }
  }
}
@media screen and (max-width: 800px) and (orientation: landscape) {
  #refund-policy {
    height: unset;
    .refund-container {
      margin-top: 13%;
    }
  }
}
</style>
