import { Endpoints } from "../../endpoints";

export const getRefundPolicyText = async () => {
  const x = await document
    .querySelector("a-scene")
    .systems.data.fetch(Endpoints.refundPolicyPage());

  const refundLang = "en";

  return x[refundLang];
};
