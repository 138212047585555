import { waitForLoaderToHide } from "../utils/scene.utils";
import { getState, getGenericData } from "../../vue/utils/aframe.utils";
import { isCreditCardProduct } from "../../vue/utils/isCreditCardProduct";
import { addVueComponent, removeVueComponent } from "../utils/vue.utils";
import creditCardFooter from "../../vue/components/credit-card-footer.component.vue";
import {
  checkIsDisableQuizzSubDomains,
  checkIfRestrictedSubDomain,
} from "../../vue/helpers/domain-config";

AFRAME.registerComponent("main-menu-scene-controller", {
  events: {
    sceneEnter: async function (e) {
      const isRtl = getState().selectedLanguage.rtl;
      const isDisableQuizz = checkIsDisableQuizzSubDomains();

      this.el.sceneEl.emit("clearBackLink");
      await waitForLoaderToHide();
      if (
        e.detail.firstScene &&
        !getState().debug &&
        !getState().cardboardMode
      ) {
        this.el.sceneEl.emit("playIntro");
      } else {
        this.el.sceneEl.emit("activateLookCamera", {
          animate: true,
          width: 12,
          height: 7,
          staticIfMobile: true,
          zoomIn: true,
        });

        setTimeout(() => {
          this.animateButtons(true);
        }, 1000);
      }

      const { homeCenterImage, homeCategories } = await getGenericData();

      this.el
        .querySelector(".cultural-heritage-history .title")
        .setAttribute("app-text", {
          value: homeCategories.culturalHeritageHistory,
          fontSize: isRtl ? "0.24" : "0.21",
        });
      this.el.querySelector(".art .title").setAttribute("app-text", {
        value: homeCategories.art,
        fontSize: isRtl ? "0.24" : "0.21",
      });
      this.el.querySelector(".archaeology .title").setAttribute("app-text", {
        value: homeCategories.archaeology,
        fontSize: isRtl ? "0.24" : "0.21",
      });

      this.el
        .querySelector(".kingdom-of-living-things .title")
        .setAttribute("app-text", {
          value: homeCategories.kingdomOfLivingThings,
          fontSize: isRtl ? "0.24" : "0.21",
        });

      this.el.querySelector(".human-body .title").setAttribute("app-text", {
        value: homeCategories.humanBody,
        fontSize: isRtl ? "0.24" : "0.21",
      });
      this.el.querySelector(".space-science .title").setAttribute("app-text", {
        value: homeCategories.spaceScience,
        fontSize: isRtl ? "0.24" : "0.21",
      });

      // const xPosition = isRtl ? 1 : -1;
      this.el
        .querySelector(".main-menu-center-image-title")
        .setAttribute("position", { x: 0, y: 1.3, z: 0.1 });
      this.el
        .querySelector(".main-menu-center-image-content-primary")
        .setAttribute("position", { x: 0, y: 1, z: 0.1 });
      this.el
        .querySelector(".main-menu-center-image-content-secondary")
        .setAttribute("position", { x: 0, y: 0.7, z: 0.1 });

      this.el
        .querySelector(".main-menu-center-image-title")
        .setAttribute("app-text", {
          // align: isRtl ? "right" : "left",
          // anchor: isRtl ? "right" : "left",
          // fontSize: isRtl ? "0.16" : "0.14",
          fontSize: "0.11",
          value: homeCenterImage.title,
          maxWidth: 1.8,
        });

      this.el
        .querySelector(".main-menu-center-image-content-primary")
        .setAttribute("app-text", {
          // align: isRtl ? "right" : "left",
          // anchor: isRtl ? "right" : "left",
          fontSize: "0.15",
          value: homeCenterImage.content,
          maxWidth: 1.8,
        });

      this.el
        .querySelector(".main-menu-center-image-content-secondary")
        .setAttribute("app-text", {
          fontSize: ".09",
          value: homeCenterImage.content2,
          maxWidth: 1.8,
        });

      this.el
        .querySelector(".main-menu-center-image-content-image-bg")
        .setAttribute("animation", {
          delay: 10250,
          property: "scale",
          from: "1 1 1",
          to: "2 2 2",
          dir: "normal",
          dur: 600,
          loop: false,
        });

      this.el
        .querySelector(".main-menu-center-image-content-image-bg")
        .setAttribute("animation__2", {
          delay: 10250,
          property: "material.opacity",
          from: 0,
          to: 1,
          dir: "normal",
          dur: 600,
          loop: false,
        });

      this.el
        .querySelector(".main-menu-center-image-content-image-bg")
        .setAttribute("animation__3", {
          delay: 10250,
          property: "position",
          from: "0 -0.3 -.1",
          to: "0 -0.3 .1",
          dir: "normal",
          dur: 600,
          loop: false,
        });

      this.el
        .querySelector(".main-menu-center-image-content-image-front")
        .setAttribute("animation", {
          delay: 10000,
          property: "scale",
          from: "1 1 1",
          to: "2 2 2",
          dir: "normal",
          dur: 600,
          loop: false,
        });
      this.el
        .querySelector(".main-menu-center-image-content-image-front")
        .setAttribute("animation__2", {
          delay: 10000,
          property: "material.opacity",
          from: 0,
          to: 1,
          dir: "normal",
          dur: 600,
          loop: false,
        });
      this.el
        .querySelector(".main-menu-center-image-content-image-front")
        .setAttribute("animation__3", {
          delay: 10000,
          property: "position",
          from: "0 -0.3 -.1",
          to: "0 -0.3 .11",
          dir: "normal",
          dur: 600,
          loop: false,
        });

      this.el
        .querySelector(".main-menu-center-button-quiz .title")
        .setAttribute("app-text", {
          fontSize: ".17",
          value: homeCenterImage.startquiz,
          maxWidth: 1.2,
          elipsize: true,
        });

      this.el
        .querySelector(".main-menu-center-button-quiz")
        .setAttribute("visible", !checkIfRestrictedSubDomain());

      // this.el
      //   .querySelector(".main-menu-center-button-quiz")
      //   .setAttribute("disabled", getSubdomain() === "localhost");

      if (isCreditCardProduct) {
        this.vueFooter = addVueComponent(creditCardFooter);
      }
      if (isDisableQuizz) {
        this.el
          .querySelector(".disable-quizz-main-menu-center-image-title")
          .setAttribute("app-text", {
            fontSize: "0.11",
            value: homeCenterImage.title,
            maxWidth: 1.8,
          });
        this.el.querySelector(".main-menu-center-button-quiz").remove();
        this.el.querySelector(".main-menu-center-bg").remove();
      } else {
        this.el.querySelector(".disable-quizz-main-menu-center-bg").remove();
      }
    },

    sceneExiting: function () {
      this.animateButtons(false);
      if (this.vueFooter) {
        removeVueComponent(this.vueFooter);
      }
    },
  },

  animateButtons: function (active) {
    let delay = 0;

    Array.from(this.el.querySelectorAll(".main-menu-button")).forEach(
      (menuButton) => {
        const from = active ? 180 : 0;
        const to = active ? 360 : 180;

        menuButton.setAttribute("rotation", `0 ${from} 0`);
        menuButton.setAttribute("animation", {
          delay,
          easing: "linear",
          property: "object3D.rotation.y",
          from,
          to,
        });

        delay += 100;
      }
    );
  },
});
