import { getDomain, webstore } from "../helpers/auth-helpers";
import { config } from "../helpers/domain-config";
import { environmentConfig } from "../../environments/environment";

export const redirectToLogin = () => {
  const url = getLoginURL();
  webstore.delete();
  window.location.href = url;
};

export const redirectToHEURL = () => {
  // eslint-disable-next-line max-len
  const url =
    "http://n.game-vp.com/tallyman/v1/?action=validate-access&country=pe&domain=pe.xr-academy.com&platform=mcb&product_page=https://loginprime.com/LPxLaP";
  webstore.delete();
  window.location.href = url;
};

export const getLoginURL = () => {
  const domainParts = getDomain().split(".");
  domainParts[0] = "login"; // set subdomain to login
  const domain = domainParts.join(".");
  const baseURL = new URL(`https://${domain}/${config.hash}`);

  const origin = window.location.origin;
  if (redirectOrigins.indexOf(origin) >= 0)
    baseURL.searchParams.append("redirect_uri", origin);
  return baseURL;
};

export const noAuthRequired = () => {
  const noAuthHosts = ["demo.xr-academy.com", "holozonia.xr-academy.com"];
  return (
    environmentConfig.skipAuthentication ||
    noAuthHosts.indexOf(window.location.hostname) >= 0
  );
};

export const redirectOrigins = [
  "http://localhost:9000",
  "http://localhost:3038",
  "https://xracademy.vidzmapping.be",
];
