var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "refund-policy" } }, [
    _vm.refundData !== null
      ? _c("div", [
          _c("div", { staticClass: "refund-container" }, [
            _c("h1", [_vm._v(_vm._s(_vm.refundData.title))]),
            _vm._v(" "),
            _c("div", { staticClass: "refund_details" }, [
              _c("p", [_vm._v(_vm._s(_vm.refundData.text1))]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.refundData.text2))]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }