var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return Object.keys(_vm.mobileMenuData).length > 0
    ? _c("div", { staticClass: "mobile-menu" }, [
        _c("div", { staticClass: "menu" }, [
          _vm.mainMenuActive
            ? _c("div", { staticClass: "Menu_container" }, [
                _c("div", { staticClass: "menu_title" }, [
                  _vm._v(_vm._s(_vm.mobileMenuData.title)),
                ]),
                _vm._v(" "),
                _c("ul", [
                  _vm.isLogin
                    ? _c(
                        "li",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.toggleProfileMenu()
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "button settings",
                            attrs: {
                              src: "/images/settings-menu/profileico.svg",
                            },
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.mobileMenuData.profile.title) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.toggleLangMenu()
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "button settings",
                        attrs: {
                          src: "/images/settings-menu/langoptionico.svg",
                        },
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.mobileMenuData.language.title) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isLogin
                    ? _c(
                        "li",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.enterVR()
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "button settings",
                            attrs: {
                              src: "/images/settings-menu/entervrico.svg",
                            },
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.mobileMenuData.enterVR) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showUnsubBUtton
                    ? _c(
                        "li",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.unsubscribe()
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "button settings",
                            attrs: {
                              src: "/images/settings-menu/unsubscribe.svg",
                            },
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.mobileMenuData.unsubscribe.title) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.termsAndConditions()
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "button settings",
                        attrs: { src: "/images/settings-menu/termsico.svg" },
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.mobileMenuData.terms) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.showMessageUs
                    ? _c(
                        "li",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.redirectToWhatsApp()
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "/images/settings-menu/whatsappIcon.png",
                            },
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.mobileMenuData.contact.message) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isCreditCardProduct
                    ? _c(
                        "li",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.faq()
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "button settings",
                            attrs: {
                              src: "/images/settings-menu/termsico.svg",
                            },
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.mobileMenuData.faq) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isCreditCardProduct
                    ? _c(
                        "li",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.redirectToHelpPage()
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "button settings",
                            attrs: {
                              src: "/images/settings-menu/termsico.svg",
                            },
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.mobileMenuData.helpCenter) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isCreditCardProduct
                    ? _c(
                        "li",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.redirectToRefundPolicy()
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "button settings",
                            attrs: {
                              src: "/images/settings-menu/termsico.svg",
                            },
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.mobileMenuData.refundPolicy) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isCreditCardProduct
                    ? _c(
                        "li",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.redirectToPrivacyPolicy()
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "button settings",
                            attrs: {
                              src: "/images/settings-menu/termsico.svg",
                            },
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.mobileMenuData.privacyPolicy) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showContactForm
                    ? _c(
                        "li",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.redirectToCustomerCarePage()
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "button settings",
                            attrs: {
                              src: "/images/settings-menu/langoptionico.svg",
                            },
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.mobileMenuData.contact.title) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isLogin
                    ? _c(
                        "li",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.logout()
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "button settings",
                            attrs: { src: "/images/settings-menu/exit.svg" },
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.mobileMenuData.logout) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showImpressum
                    ? _c("li", { on: { click: _vm.redirectToImpressum } }, [
                        _c("img", {
                          staticClass: "button settings",
                          attrs: { src: "/images/settings-menu/termsico.svg" },
                        }),
                        _vm._v("\n          Impressum\n        "),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showWinnersPage
                    ? _c(
                        "li",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.winners()
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "button settings",
                            attrs: {
                              src: "/images/settings-menu/langoptionico.svg",
                            },
                          }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.mobileMenuData.winners) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.profileMenuActive
            ? _c("div", { staticClass: "profile_container" }, [
                _c(
                  "div",
                  {
                    staticClass: "profile_menu",
                    on: {
                      click: function ($event) {
                        return _vm.toggleProfileMenu()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.mobileMenuData.title) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "profile_data" }, [
                  _c("div", { staticClass: "profile_img" }, [
                    _c("img", {
                      attrs: { src: "/images/settings-menu/avatarico.svg" },
                    }),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(_vm._s(_vm.mobileMenuData.profile.subTitle)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("label", [
                    _vm._v(_vm._s(_vm.mobileMenuData.profile.phoneNumber)),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.profile.msisdn,
                        expression: "profile.msisdn",
                      },
                    ],
                    attrs: {
                      type: "text",
                      name: "Mobile Number",
                      value: "0990 90 90",
                      disabled: "",
                    },
                    domProps: { value: _vm.profile.msisdn },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.profile, "msisdn", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.showSubscriptionEndDate
                    ? _c("div", [
                        _c("label", [
                          _vm._v(_vm._s(_vm.mobileMenuData.profile.endDate)),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.profile.renewalDate,
                              expression: "profile.renewalDate",
                            },
                          ],
                          attrs: {
                            type: "text",
                            name: "End Date",
                            value: "2021-10-01",
                            disabled: "",
                          },
                          domProps: { value: _vm.profile.renewalDate },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.profile,
                                "renewalDate",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.langMenuActive
            ? _c("div", { staticClass: "lang_container" }, [
                _c(
                  "div",
                  {
                    staticClass: "lang_menu",
                    on: {
                      click: function ($event) {
                        return _vm.toggleLangMenu()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.mobileMenuData.title) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "lang_title" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.mobileMenuData.language.subTitle) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  _vm._l(_vm.availableLanguages, function (language) {
                    return _c(
                      "li",
                      {
                        key: language.id,
                        class: {
                          selected: language.id === _vm.selectedLanguage,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.selectLanguage(language)
                          },
                        },
                      },
                      [
                        language.id === _vm.selectedLanguage
                          ? _c("img", {
                              attrs: {
                                src: "/images/settings-menu/langselection.svg",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            class: {
                              active_lang: language.id === _vm.selectedLanguage,
                            },
                          },
                          [_vm._v(_vm._s(language.value))]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.UnsubMenuActive
            ? _c("div", { staticClass: "Unsub_container" }, [
                _c(
                  "div",
                  {
                    staticClass: "Unsub_menu",
                    on: {
                      click: function ($event) {
                        return _vm.unsubscribe()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.mobileMenuData.title) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                !this.idUnsubMenuActive
                  ? _c("div", { staticClass: "Unsub_info" }, [
                      _c("div", { staticClass: "Unsub_data" }, [
                        _c("img", {
                          attrs: { src: "/images/settings-menu/avatarico.svg" },
                        }),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(_vm._s(_vm.mobileMenuData.unsubscribe.title)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "Unsub_info" }, [
                        _c("p", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.mobileMenuData.unsubscribe.unsubInfo) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "Unsub_btn",
                            on: {
                              click: function ($event) {
                                return _vm.redirectToUnsubPage()
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.mobileMenuData.unsubscribe.title)
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.showErrorMessage
                          ? _c(
                              "p",
                              {
                                staticStyle: {
                                  color: "red",
                                  "font-size": "12px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.errorMessage) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.idUnsubMenuActive
                  ? _c("div", [
                      _c("p", { staticClass: "id_domain_unsub_title" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.mobileMenuData.unsubscribe.title) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { staticClass: "id_domain_unsub" },
                        _vm._l(
                          _vm.idAavailableUnsubLinks,
                          function (unsubscribeLink) {
                            return _c(
                              "li",
                              {
                                key: unsubscribeLink.id,
                                on: {
                                  click: function ($event) {
                                    return _vm.redirectToUnsubPage(
                                      unsubscribeLink.id
                                    )
                                  },
                                },
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(unsubscribeLink.value)),
                                ]),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "close-button",
              on: {
                click: function ($event) {
                  return _vm.closeDialogBox()
                },
              },
            },
            [_c("img", { attrs: { src: "/images/ui/exit-button.png" } })]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }