import axios from "axios";

export const getWinners = async (msisdn) => {
  try {
    const response = await axios.get(
      `https://quiz.xr-academy.com/api/auth/checkWinner?msisdn=${msisdn}`
    );
    return response.data;
  } catch (err) {
    console.log("ee", err);
  }
};
