import { resetCameraRotation } from "./utils/camera.utils";
import { environmentConfig } from "../environments/environment";
import { getSelectedLanguage } from "./utils/local-storage.utils";

export const CAMERA_CONTROLS = {
  NONE: "none",
  LOOK: "look",
  ORBIT: "orbit",
};

export const LANGUAGES = {
  ar: {
    fontOverride: "Scheherazade",
    id: "ar",
    rtl: true,
  },
  de: {
    fontOverride: null,
    id: "de",
    rtl: false,
  },
  it: {
    fontOverride: null,
    id: "it",
    rtl: false,
  },
  en: {
    fontOverride: null,
    id: "en",
    rtl: false,
  },
  nl: {
    fontOverride: null,
    id: "nl",
    rtl: false,
  },
  es: {
    fontOverride: null,
    id: "es",
    rtl: false,
  },
  in: {
    fontOverride: null,
    id: "in",
    rtl: false,
  },
  fr: {
    fontOverride: null,
    id: "fr",
    rtl: false,
  },
  pl: {
    fontOverride: null,
    id: "pl",
    rtl: false,
  },
  cz: {
    fontOverride: null,
    id: "cz",
    rtl: false,
  },
  rs: {
    fontOverride: null,
    id: "rs",
    rtl: false,
  },
  et: {
    fontOverride: null,
    id: "et",
    rtl: false,
  },
  vn: {
    fontOverride: null,
    id: "vn",
    rtl: false,
  },
  se: {
    fontOverride: null,
    id: "se",
    rtl: false,
  },
  th: {
    fontOverride: "ThaiSarbun",
    id: "th",
    rtl: false,
  },
};

async function init() {
  const browser = require("detect-browser").detect();
  const isAndroid = browser.os === "Android OS";
  const isIOS = browser.os === "iOS";

  let arMode = null;
  if (
    isAndroid &&
    navigator &&
    navigator.xr &&
    (await navigator.xr.isSessionSupported("immersive-ar"))
  ) {
    arMode = "webxr";
  } else if (isIOS && document.createElement("a").relList.supports("ar")) {
    arMode = "quicklook";
  }

  AFRAME.registerState({
    initialState: {
      debug:
        !environmentConfig.production && window.location.search === "?debug",

      activeCamera: "#camera-look-controls",
      arMode,
      backLink: "",
      browser,
      cameraControls: CAMERA_CONTROLS.NONE,
      cameraTransform: {
        animation: null,
        position: { x: 0, y: 0, z: 0 },
        rotation: { x: 0, y: 0, z: 0 },
      },
      cameraZoom: {
        debug: "1 1 1",
        enabled: false,
        height: 1,
        width: 1,
      },
      cardboardMode: false,
      cursorColor: "white",
      cursorRaycastable: null,
      environmentPreset: null,
      firstScene: true,
      hasVuePage: false,
      isAndroid,
      isIOS,
      isMobile: require("aframe/src/utils/device").isMobile(),
      isProductionEnvironment: environmentConfig.production,
      lookControlsActive: true,
      mouseDetected: false,
      orbitControls: {
        maxDistance: 100,
        minDistance: 5,
        panSpeed: null,
        rotateSpeed: null,
        target: { x: 0, y: 1.6, z: -50 },
      },
      playIntro: false,
      routeParameters: {},
      scene: null,
      sceneInformation: {
        introduction: {
          content: null,
          title: null,
        },
        didYouKnow: {
          content: null,
          title: null,
        },
        topic: null,
      },
      selectedLanguage: LANGUAGES[getSelectedLanguage()],
      showLoader: false,
      showLoaderText: false,
      transitioning: false,
      userIsInVR: false,
      vrCameraPosition: {
        x: 0,
        y: 0,
        z: 0,
      },
      worldPosition: "0 0 0",
    },

    computeState: function (newState) {
      newState.cursorRaycastable = newState.transitioning
        ? "[raycasting-disabled]"
        : `#scene-${newState.scene} [data-raycastable], #scene-${
            newState.scene
          } [data-raycastable-${newState.userIsInVR ? "vr" : "desktop"}]`;
      newState.worldPosition =
        newState.userIsInVR && !newState.cardboardMode ? "0 1.6 0" : "0 0 0";
    },

    handlers: {
      activateLookCamera: function (state, action) {
        if (!action) {
          action = {};
        }

        const { staticIfMobile } = action;

        if (staticIfMobile && state.isMobile) {
          this.activateStaticCamera(state, action);
          return;
        }

        state.activeCamera = "#camera-look-controls";
        state.cameraControls = CAMERA_CONTROLS.LOOK;
        this.zoomCamera(state, action);

        document.querySelector("a-scene").emit("disableLookControls");
        resetCameraRotation();
        setTimeout(() =>
          document.querySelector("a-scene").emit("enableLookControls")
        );
      },

      activateOrbitalCamera: function (state, orbitControls) {
        state.activeCamera = "#camera-orbit-controls";
        state.cameraControls = CAMERA_CONTROLS.ORBIT;
        state.orbitControls = {
          ...state.orbitControls,
          ...orbitControls,
          panSpeed: orbitControls.panSpeed || null,
          rotateSpeed: orbitControls.rotateSpeed || null,
        };
        state.cameraZoom.enabled = false;
      },

      activateStaticCamera: function (state, action) {
        state.activeCamera = "#camera-look-controls";
        state.cameraControls = CAMERA_CONTROLS.NONE;
        this.zoomCamera(state, action);
        resetCameraRotation();
      },

      clearBackLink: function (state) {
        state.backLink = null;
      },

      clearSceneInformation: function (state) {
        state.sceneInformation.didYouKnow = {
          title: null,
          content: null,
        };
        state.sceneInformation.introduction = {
          title: null,
          content: null,
        };
        this.disableSceneInformationTopic(state);
      },

      disableCardboardMode: function (state) {
        window.localStorage.removeItem("cardboard-mode");

        state.cardboardMode = false;
        state.userIsInVR = false;
      },

      disableLookControls: function (state) {
        state.lookControlsActive = false;
      },

      disableSceneInformationTopic: function (state) {
        state.sceneInformation.topic = null;
      },

      enableCardboardMode: function (state) {
        window.localStorage.setItem("cardboard-mode", "true");

        state.cardboardMode = true;
        state.lookControlsActive = true;
        state.userIsInVR = true;
      },

      enableLookControls: function (state) {
        state.lookControlsActive = true;
      },

      hideLoader: function (state) {
        state.showLoader = false;
      },

      hideLoaderText: function (state) {
        state.showLoaderText = false;
      },

      invertCursorColor: function (state, invert) {
        state.cursorColor = invert ? "black" : "white";
      },

      mouseDetected: function (state) {
        state.mouseDetected = true;
      },

      playIntro: function (state) {
        state.playIntro = true;
      },

      resetVRCameraPosition: function (state) {
        state.vrCameraPosition = {
          x: 0,
          y: 0,
          z: 0,
        };
      },

      setBackLink: function (state, backLink) {
        state.backLink = backLink;
      },

      setCameraTransform: function (state, data) {
        const { position, rotation, animation } = data;

        state.cameraTransform.animation = JSON.stringify(animation);
        state.cameraTransform.position = position;
        state.cameraTransform.rotation = rotation;
      },

      setHasVuePage: function (state, hasVuePage) {
        state.hasVuePage = hasVuePage;
      },

      setFirstScene: function (state, firstScene) {
        state.firstScene = firstScene;
      },

      setScene: function (state, action) {
        const { routeParameters, scene } = action;
        state.routeParameters = routeParameters || {};
        state.scene = scene;
      },

      setSceneInformation: function (state, data) {
        ["didYouKnow", "introduction"].forEach((topic) => {
          if (data[topic]) {
            state.sceneInformation[topic] = data[topic];
          } else {
            state.sceneInformation[topic] = {
              content: null,
              title: null,
            };
          }
        });
      },

      toggleSceneInformationTopic: function (state, topic) {
        state.sceneInformation.topic =
          state.sceneInformation.topic === topic ? null : topic;
      },

      setTransitioning: function (state, transitioning) {
        state.transitioning = transitioning;
      },

      setVRCameraPosition: function (state, position) {
        state.vrCameraPosition = position;
      },

      setUserIsInVR: function (state, userIsInVR) {
        state.userIsInVR = userIsInVR;
      },

      showLoader: function (state) {
        state.showLoader = true;
      },

      showLoaderText: function (state) {
        state.showLoaderText = true;
      },

      zoomCamera: function (state, data) {
        const { animate, width, height, zoomIn } = data;
        state.cameraZoom = {
          animate: animate === true,
          debug: `${width} ${height} 0`,
          enabled: !!height && !!width,
          height,
          width,
          zoomIn: zoomIn === true,
        };
      },
    },
  });
}

init();
