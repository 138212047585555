var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "faq" } }, [
    _vm.faqData !== null
      ? _c("div", [
          _c("div", { staticClass: "faq_container" }, [
            _c("h1", [_vm._v(_vm._s(_vm.faqData.title))]),
            _vm._v(" "),
            _c("div", { staticClass: "faq_details_container" }, [
              _c("div", { staticClass: "faq_details" }, [
                _c("h3", [_vm._v(_vm._s(_vm.faqData.ques1))]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.faqData.ans1))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "faq_details" }, [
                _c("h3", [_vm._v(_vm._s(_vm.faqData.ques2))]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.faqData.ans2))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "faq_details" }, [
                _c("h3", [_vm._v(_vm._s(_vm.faqData.ques3))]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.faqData.ans3))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "faq_details" }, [
                _c("h3", [_vm._v(_vm._s(_vm.faqData.ques4))]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.faqData.ans4))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "faq_details" }, [
                _c("h3", [_vm._v(_vm._s(_vm.faqData.ques5))]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.faqData.ans5))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "faq_details" }, [
                _c("h3", [_vm._v(_vm._s(_vm.faqData.ques7))]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.faqData.ans7))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "faq_details" }, [
                _c("h3", [_vm._v(_vm._s(_vm.faqData.ques8))]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.faqData.ans8))]),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }