import mixpanel from "mixpanel-browser";
import { getUid } from "./vue/helpers/auth-helpers";

const MIXPANEL_ACTIVE = false;
mixpanel.init("31148f268ad5bf087da7d359bf6af54c", {
  debug: true,
  track_pageview: true,
  autotrack: true,
  persistence: "localStorage",
});
const envCheck = MIXPANEL_ACTIVE;

const actions = {
  identify: (id) => {
    if (envCheck) mixpanel.identify(id);
  },
  alias: (id) => {
    if (envCheck) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (envCheck) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (envCheck) mixpanel.people.set(props);
    },
  },
};

export const setMixpanelUser = (user) => {
  if (!envCheck) return;
  if (user) {
    actions.identify(user.id);
    actions.people.set({
      $name: user.id,
    });
  }
};

export const setMixpanelPageView = (args) => {
  if (envCheck) actions.track("XRACADEMY_PAGE_VIEW", args);
};

export const setUsersClickedOnCheckButton = (args) => {
  const uid = getUid();

  if (envCheck)
    actions.track("XRACADEMY_WINNERS_BUTTON_CLICKED", { ...args, uid });
};

export const setUsersEngagedWithPage = (args) => {
  const uid = getUid();
  if (envCheck)
    actions.track("XRACADEMY_WINNERS_PAGE_ENGAGED", { ...args, uid });
};

export const setCheckWinnerResponse = (args) => {
  const uid = getUid();
  if (envCheck)
    actions.track("XRACADEMY_WINNERS_API_RESPONSE", { ...args, uid });
};

export const Mixpanel = actions;
