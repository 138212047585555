import CardboardModeInstructions from "../../vue/components/cardboard-mode-instructions.component.vue";
import EnterVrButton from "../../vue/components/enter-vr-button.component.vue";
import Header from "../../vue/components/header.component.vue";
import Loader from "../../vue/components/loader.component.vue";
import { addVueComponent } from "../utils/vue.utils";
import { config } from "../../vue/helpers/domain-config";
import WhatsAppChat from "../../vue/components/whatsapp-chat.components.vue";
// import ChatGpt from "../../vue/components/chatgpt.component.vue";

AFRAME.registerSystem("vue-ux", {
  init: function () {
    this.onSetScene = this.onSetScene.bind(this);
    this.el.sceneEl.addEventListener("setScene", this.onSetScene);
    setTimeout(() => {
      if (config.subdomain !== "holozonia") {
        addVueComponent(EnterVrButton, {}, document.querySelector("#vue-ui"));
        addVueComponent(Header, {}, document.querySelector("#vue-ui"));
      }
      if (config.subdomain === "my") {
        addVueComponent(WhatsAppChat, {}, document.querySelector("#vue-ui"));
      }
      addVueComponent(CardboardModeInstructions, {}, document.body);
      addVueComponent(Loader, {}, document.querySelector("#vue-ui"));
      // addVueComponent(ChatGpt, {}, document.querySelector("#vue-ui"));
      // if (getState().isMobile) {
      //   addVueComponent(MobileLandscapeInstructions, {}, document.body);
      // }
    }, 1000);
  },

  onSetScene: function (e) {
    this.el.sceneEl.removeEventListener("setScene", this.onSetScene);

    const { scene } = e.detail;
    if (scene === "main_menu") {
      return;
    }
    this.showVueElements();
  },

  showVueElements: function (animated) {
    const elHeader = document.querySelector("#vue-ui .header-component");
    const elVRButton = document.getElementById("enter-vr-button");
    const elWhatsAppButton = document.getElementById("whatsapp_icon");
    const mySubdomain = config.subdomain === "my";
    // const elChatGpt = document.querySelector("#vue-ui .chatgpt-component");
    if (!elHeader || !elVRButton || (mySubdomain && !elWhatsAppButton)) {
      setTimeout(() => this.showVueElements());
      return;
    }

    if (config.subdomain !== "holozonia") {
      if (animated) {
        elHeader.classList.add("animated");
        elVRButton.classList.add("animated");
        if (elWhatsAppButton) {
          elWhatsAppButton.classList.add("animated");
        }
        // if (elChatGpt) {
        //   elChatGpt.classList.add("animated");
        // }
      }
      if (elWhatsAppButton) {
        elWhatsAppButton.classList.add("visible");
      }
      elHeader.classList.add("visible");
      elVRButton.classList.add("visible");
      // if (elChatGpt) {
      //   elChatGpt.classList.add("visible");
      // }
    }
  },
});
