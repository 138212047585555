<template>
  <div id="terms-and-conditions">
    <div v-if="pageText !== null">
      <div class="terms-conditions-head">
        <h1 class="text_1">{{ pageText.text_1 }}</h1>
      </div>
      <div class="terms-condition-container">
        <p>{{ replaceText(pageText.text_2) }}</p>
        <p>{{ replaceText(pageText.text_3) }}</p>
        <h3>{{ replaceText(pageText.q1) }}</h3>
        <p>{{ replaceText(pageText.text_4) }}</p>
        <p>{{ replaceText(pageText.text_5) }}</p>
        <h3>{{ replaceText(pageText.q2) }}</h3>
        <p>{{ replaceText(pageText.text_6) }}</p>
        <h3>{{ replaceText(pageText.q3) }}</h3>
        <p>{{ replaceText(pageText.text_7) }}</p>
        <h3>{{ replaceText(pageText.q4) }}</h3>
        <p>{{ replaceText(pageText.text_8) }}</p>
        <h3>{{ replaceText(pageText.q5) }}</h3>
        <p>{{ replaceText(pageText.text_9) }}</p>
        <p>{{ replaceText(pageText.text_10) }}</p>
        <h3>{{ replaceText(pageText.q6) }}</h3>
        <p>{{ replaceText(pageText.text_11) }}</p>
        <p>{{ replaceText(pageText.text_12) }}</p>
        <h3>{{ replaceText(pageText.q7) }}</h3>
        <p>{{ replaceText(pageText.text_13) }}</p>
        <h3>{{ replaceText(pageText.q8) }}</h3>
        <p>{{ replaceText(pageText.text_14) }}</p>
        <h3>{{ replaceText(pageText.q9) }}</h3>
        <p>{{ replaceText(pageText.text_15) }}</p>
        <h3>{{ replaceText(pageText.q10) }}</h3>
        <p>{{ replaceText(pageText.text_16) }}</p>
        <p>{{ replaceText(pageText.text_17) }}</p>
        <ul>
          <li>
            <span>{{ replaceText(pageText.text_18) }}</span>
          </li>
          <li>
            <span>{{ replaceText(pageText.text_19) }}</span>
          </li>
        </ul>
        <p>{{ replaceText(pageText.text_20) }}</p>
        <p>{{ replaceText(pageText.text_21) }}</p>
        <p>{{ replaceText(pageText.text_22) }}</p>
        <h3>{{ replaceText(pageText.q11) }}</h3>
        <p>{{ replaceText(pageText.text_23) }}</p>
        <p>{{ replaceText(pageText.text_24) }}</p>
        <ul>
          <li>
            <span>{{ replaceText(pageText.text_25) }}</span>
          </li>
          <li>
            <span>{{ replaceText(pageText.text_26) }}</span>
          </li>
          <li>
            <span>{{ replaceText(pageText.text_66) }}</span>
          </li>
        </ul>
        <h3>{{ replaceText(pageText.q12) }}</h3>
        <ul class="feature_container">
          <li>
            <span>{{ replaceText(pageText.text_28) }}</span>
          </li>
          <p>{{ replaceText(pageText.text_29) }}</p>
          <p>{{ replaceText(pageText.text_30) }}</p>
          <li>
            <span>{{ replaceText(pageText.text_31) }}</span>
          </li>
          <p>{{ replaceText(pageText.text_32) }}</p>
          <li>
            <span>{{ replaceText(pageText.text_33) }}</span>
          </li>
          <p>{{ replaceText(pageText.text_34) }}</p>
          <p>{{ replaceText(pageText.text_35) }}</p>
          <p>{{ replaceText(pageText.text_36) }}</p>
        </ul>
        <h3>{{ replaceText(pageText.q13) }}</h3>
        <p>{{ replaceText(pageText.text_37) }}</p>
        <p>{{ replaceText(pageText.text_38) }}</p>
        <p>{{ replaceText(pageText.text_39) }}</p>
        <p>{{ replaceText(pageText.text_40) }}</p>
        <p>{{ replaceText(pageText.text_41) }}</p>
        <p>{{ replaceText(pageText.text_42) }}</p>
        <h4>{{ replaceText(pageText.q14) }}</h4>
        <h3>{{ replaceText(pageText.q15) }}</h3>
        <p>{{ replaceText(pageText.text_43) }}</p>
        <p>{{ replaceText(pageText.text_44) }}</p>
        <h3>{{ replaceText(pageText.q16) }}</h3>
        <p>{{ replaceText(pageText.text_45) }}</p>
        <h3>{{ replaceText(pageText.q17) }}</h3>
        <p>{{ replaceText(pageText.text_46) }}</p>
        <h3>{{ replaceText(pageText.q18) }}</h3>
        <p>{{ replaceText(pageText.text_47) }}</p>
        <p>{{ replaceText(pageText.text_48) }}</p>
        <p>{{ replaceText(pageText.text_49) }}</p>
        <ul>
          <li>
            <span>{{ replaceText(pageText.text_25) }}</span>
          </li>
          <li>
            <span>{{ replaceText(pageText.text_26) }}</span>
          </li>
          <li>
            <span>{{ replaceText(pageText.text_66) }}</span>
          </li>
        </ul>
        <h3>{{ replaceText(pageText.q19) }}</h3>
        <p>{{ replaceText(pageText.text_52) }}</p>
        <h3>{{ replaceText(pageText.q20) }}</h3>
        <p>{{ replaceText(pageText.text_53) }}</p>
        <h3>{{ replaceText(pageText.q21) }}</h3>
        <p>{{ replaceText(pageText.text_54) }}</p>
        <ol>
          <li>
            <span>{{ replaceText(pageText.text_55) }}</span>
          </li>
          <li>
            <span>{{ replaceText(pageText.text_56) }}</span>
          </li>
          <li>
            <span>{{ replaceText(pageText.text_57) }}</span>
          </li>
          <li>
            <span>{{ replaceText(pageText.text_58) }}</span>
          </li>
          <li>
            <span>{{ replaceText(pageText.text_59) }}</span>
          </li>
          <li>
            <span>{{ replaceText(pageText.text_60) }}</span>
          </li>
        </ol>
        <p>{{ replaceText(pageText.text_61) }}</p>
        <h3>{{ replaceText(pageText.q22) }}</h3>
        <p>{{ replaceText(pageText.text_62) }}</p>
        <h3>{{ replaceText(pageText.q23) }}</h3>
        <p>{{ replaceText(pageText.text_63) }}</p>
        <h3>{{ replaceText(pageText.q24) }}</h3>
        <p>{{ replaceText(pageText.text_64) }}</p>
        <h3>{{ replaceText(pageText.q25) }}</h3>
        <p>{{ replaceText(pageText.text_65) }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { waitForLoaderToHide } from "../../aframe/utils/scene.utils";
import { getCreditCardTermsText } from "../helpers/terms-page-data.helper";

export default {
  async created() {
    waitForLoaderToHide();
    this.pageText = await getCreditCardTermsText();
  },
  data() {
    return {
      pageText: null,
      currentDomain: window.location.hostname,
    };
  },
  methods: {
    replaceText(text) {
      if (this.currentDomain === "portal.xracademy.online") {
        if (text) {
          return text
            .replace(/AMB Global Digital Solutions LTD/g, "Sam Media B.V.")
            .replace(/info@ambglobaldigital.com/g, "help@xracademy.online");
        }
      }
      return text;
    },
  },
};
</script>

<style lang="scss">
#terms-and-conditions {
  background-color: #efefef;
  cursor: auto;
  padding: 25px 30px;
  color: #3a3a3a;
  font-family: "Helvetica Neue";
  .feature_container {
    li {
      margin-top: 2%;
    }
  }

  h3 {
    font-size: 19px;
    line-height: 23px;
    color: #3f3f3f;
  }
  .terms-conditions-head {
    margin-top: 5%;
    text-align: center;
  }
  .terms-condition-container {
    margin-top: 4%;
    padding: 0 3%;
    ul,
    ol {
      li {
        span {
          font-size: 14px;
        }
      }
    }
    p {
      font-size: 14px;
      font-weight: normal;
      line-height: 19px;
      padding: 0;
      margin-top: 1%;
    }
  }
}
</style>
