<template>
  <div class="credit_card_footer_main_container">
    <div class="credit_card_footer_inner_container">
      <div class="credit_card_details">
        <p class="credit_card_address">{{ modifiedAddress }}</p>
        <p class="credit_card_link">{{ modifiedLink }}</p>
      </div>
      <div class="footer_img">
        <img src="/images/credit-card-footer/visa.png" alt="visa" />
        <div class="vertical_line"></div>
        <img src="/images/credit-card-footer/mastercard.png" alt="mastercard" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    modifiedAddress: function () {
      if (this.currentDomain === "portal.xracademy.online") {
        return this.content.replace(
          "AMB GLOBAL DIGITAL SOLUTIONS LTD 1 Maiou 3, 2610 Agios Epifanios Oreinis, Cyprus",
          "Sam Media B.V. Van Diemenstraat 356, 1013 CR Amsterdam, The Netherlands");
      } else {
        return this.content;
      }
    },
    modifiedLink: function () {
      if (this.currentDomain === "portal.xracademy.online") {
        return this.link.replace(
          "www.ambglobaldigital.com",
          "www.sammedia.com"
        );
      } else {
        return this.link;
      }
    }
  },
  data() {
    return {
      content: "AMB GLOBAL DIGITAL SOLUTIONS LTD 1 Maiou 3, 2610 Agios Epifanios Oreinis, Cyprus",
      link: "www.ambglobaldigital.com",
      currentDomain: window.location.hostname
    };
  },
};
</script>

<style lang="scss">
.credit_card_footer_main_container {
  display: flex;
  justify-content: center;
  font-family: "Lato", helvetica bold;
  position: fixed;
  width: 100%;
  color: #fff;
  z-index: 10;
  bottom: 57px;
  .credit_card_footer_inner_container {
    background-color: #161616;
    color: #fff;
    display: flex;
    justify-content: space-between;
    width: 65%;
    padding: 5px 35px;
    border-radius: 10px;

    p {
      margin: 0;
    }

    .credit_card_details {
      display: flex;
      justify-content: center;
      flex-direction: column;

      .credit_card_address {
        font-size: 16px;
        margin-bottom: 4px;
      }

      .credit_card_link {
        font-size: 14px;
      }
    }
    .footer_img {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 18%;

      .vertical_line {
        background: #262626;
        height: 30px;
        width: 2px;
      }

      img {
        width: 55px;
        height: 55px;
        object-fit: contain;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    bottom: 100px;
    .credit_card_footer_inner_container {
      width: 85%;
      padding: 15px 35px;
      font-size: 14px;
      .footer_img {
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  @media screen and (max-width: 680px) {
    position: absolute;
    top: 96%;
    bottom: unset;
    .credit_card_footer_inner_container {
      width: 100%;
      border-radius: 0;
      padding: 15px 20px;

      .credit_card_details {
        .credit_card_address {
          font-size: 14px;
        }
        .credit_card_link {
          font-size: 12px;
        }
      }

      .footer_img {
        flex-direction: column;
      }
      .vertical_line {
        display: none;
      }
      .footer_img {
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  @media screen and (max-width: 825px) and (orientation: landscape) {
    .credit_card_footer_main_container {
      position: relative;
    }
  }
}
</style>
