var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "help-center" } }, [
    _c("div", { staticClass: "help-center-container" }, [
      _c("div", { staticClass: "help_details" }, [
        _c("h1", [_vm._v("Help Center")]),
        _vm._v(" "),
        _c("span", [
          _vm._v(
            "\n        XR Academy is a subscription-based edutainment platform. We're here to\n        revolutionise the global learning landscape. If you have any questions\n        or need assistance, feel free to get in touch with our customer care\n        team.\n      "
          ),
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm.msisdnPresent
          ? _c(
              "div",
              { staticClass: "Unsub_btn", on: { click: _vm.unsubscribe } },
              [_c("span", [_vm._v("Unsubscribe")])]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showErrorMessage
          ? _c("p", { staticStyle: { color: "red", "font-size": "12px" } }, [
              _vm._v("\n        " + _vm._s(_vm.errorMessage) + "\n      "),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "contact_container" }, [
      _c("div", { staticClass: "contact_list" }, [
        _c("img", {
          attrs: { src: "images/credit-card-ui/email.png", alt: "email" },
        }),
        _vm._v(" "),
        _c("p", [_vm._v("help@xr-academy.com")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "contact_list" }, [
        _c("img", {
          attrs: { src: "images/credit-card-ui/phone-call.png", alt: "phone" },
        }),
        _vm._v(" "),
        _c("p", [_vm._v("0805 98 54 98 (France)")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "contact_list" }, [
        _c("img", {
          attrs: { src: "images/credit-card-ui/phone-call.png", alt: "phone" },
        }),
        _vm._v(" "),
        _c("p", [_vm._v("900 751 051 (Spain)")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "contact_list" }, [
        _c("img", {
          attrs: { src: "images/credit-card-ui/phone-call.png", alt: "phone" },
        }),
        _vm._v(" "),
        _c("p", [_vm._v("800 785 693 (Italy)")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }