import { Endpoints } from "../../endpoints";

let sceneEl;

export function getState() {
  return getScene().systems.state.state;
}

export function transitionTo(commands) {
  getScene().systems["router"].navigate(commands);
}

export function getScene() {
  if (!sceneEl) {
    sceneEl = document.querySelector("a-scene");
  }

  return sceneEl;
}

export async function getGenericData() {
  return await getScene().systems["data"].fetch(Endpoints.generic());
}

export async function getChatMetaData() {
  return await getScene().systems["data"].fetch(Endpoints.chatMetaData());
}
