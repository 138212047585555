<template>
  <div id="faq">
    <div v-if="faqData !== null">
      <div class="faq_container">
        <h1>{{ faqData.title }}</h1>
        <div class="faq_details_container">
          <div class="faq_details">
            <h3>{{ faqData.ques1 }}</h3>
            <span>{{ faqData.ans1 }}</span>
          </div>
          <div class="faq_details">
            <h3>{{ faqData.ques2 }}</h3>
            <span>{{ faqData.ans2 }}</span>
          </div>
          <div class="faq_details">
            <h3>{{ faqData.ques3 }}</h3>
            <span>{{ faqData.ans3 }}</span>
          </div>
          <div class="faq_details">
            <h3>{{ faqData.ques4 }}</h3>
            <span>{{ faqData.ans4 }}</span>
          </div>
          <div class="faq_details">
            <h3>{{ faqData.ques5 }}</h3>
            <span>{{ faqData.ans5 }}</span>
          </div>
          <!-- <div class="faq_details">
            <h3>{{ faqData.ques6 }}</h3>
            <span>{{ faqData.ans6 }}</span>
          </div> -->
          <div class="faq_details">
            <h3>{{ faqData.ques7 }}</h3>
            <span>{{ faqData.ans7 }}</span>
          </div>
          <div class="faq_details">
            <h3>{{ faqData.ques8 }}</h3>
            <span>{{ faqData.ans8 }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { waitForLoaderToHide } from "../../aframe/utils/scene.utils";
import { getFaqText } from "../helpers/faq-page-data.helper";

export default {
  async created() {
    waitForLoaderToHide();
    this.faqData = await getFaqText();
  },
  async mounted() {
    this.faqData = await getFaqText();
  },
  data: () => ({
    faqData: null,
  }),
};
</script>

<style lang="scss">
.faq_container {
  background-color: #efefef;
  cursor: auto;
  padding: 25px 30px;
  color: #3a3a3a;
  font-family: "Helvetica Neue";

  .faq_details_container {
    margin-bottom: 10%;
  }

  h1 {
    font-size: 22px;
    line-height: 23px;
    margin-bottom: 5px;
    color: #3f3f3f;
    text-align: center;
    margin-top: 3%;
  }
  h3 {
    font-size: 17px;
    font-weight: bold;
    line-height: 21px;
    padding-top: 21px;
    color: #3f3f3f;
  }
  span {
    font-size: 13px;
    font-weight: normal;
    line-height: 19px;
    margin: 0;
    padding: 0;
  }
}
@media screen and (max-width: 600px) {
  .faq_container {
    margin-bottom: 20%;
    h1 {
      margin-top: 55px;
    }
    .faq_details_container {
      margin-bottom: 21%;
    }
  }
}
@media screen and (max-width: 1024px) and (orientation: landscape) {
  .faq_container {
    .faq_details_container {
      margin-bottom: 15%;
    }
    h1 {
      margin-top: 6%;
    }
  }
}
@media screen and (max-width: 853px) and (orientation: landscape) {
  .faq_container {
    .faq_details_container {
      margin-bottom: 19%;
    }
  }
}
</style>
