import { Endpoints } from "../../endpoints";

export const getPrivacyPolicyText = async () => {
  const x = await document
    .querySelector("a-scene")
    .systems.data.fetch(Endpoints.privacyPolicyPage());

  const privacyPolicyLang = "en";

  return x[privacyPolicyLang];
};
