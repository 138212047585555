import { OpenAIApi, Configuration } from "openai";
// import WebSocket from "ws";
// import OpenAI from "openai";
// const key = process.env.VUE_APP_OPENAI_API_KEY;
// Default chatgpt key of arpansardar1988@gmail.com
// const key =
//   "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";
// XR key from TAU
// const key = "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";
// Xr key of arpansardar1988@gmail.com
const key =
  "sk-DGos-Wg-urEn888Ar0VH1DdhPZB2H0p3vZut7MHDURT3BlbkFJYuz8g5PrQ2cm0XAFHEF78Q8IycxEOX7MJj-ioSW-IA";

// xr project key of arpan@sam-media.com
// const key = "";
// const key =
//   "sk-7tt_TYrT5QkGUsBCmlNcdLtGRIvTUGXyD-RoTlpDP_T3BlbkFJpYqw-35lp_b6AnbVxFeI5waq7fltujWii-GsiMevYA";

// const url =
//   "wss://api.openai.com/v1/realtime?model=gpt-4o-realtime-preview-2024-10-01";
// const socket = new WebSocket(url, {
//   headers: {
//     Authorization: "Bearer " + process.env.VUE_APP_OPENAI_API_KEY,
//     "OpenAI-Beta": "realtime=v1",
//   },
// });

const openai = new OpenAIApi(
  new Configuration({
    apiKey: key || "",
    dangerouslyAllowBrowser: true,
    organization: "org-SyLUP9G6IJwrJNnABrTp4Njq",
    project: "proj_Hv7tgt24Etcr6c4AECoclbHT",
  })
);

export { openai, key };
