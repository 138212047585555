import axios from "axios";
import { config } from "../helpers/domain-config";

export const isCreditCardProduct = config.subdomain === "portal" ? true : false;

export const UnsubscribeForCreditCard = async (email) => {
  try {
    const response = await axios.request({
      url: "https://cc.tallymans.com/api/v1/portal/unsubscribe",
      method: "POST",
      data: { email: email },
    });
    if (response.data.state === "success") {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};
