<template>
  <div class="chatgpt-component">
    <div @click.stop="openChatGpt()" class="chat-btn" id="chatgpt-button">
      <img src="/images/ui/XrBot.svg" class="xr_bot" alt="" />
    </div>
    <div
      :class="{ visible: isChatWindowActive, animated: isChatWindowActive }"
      class="chatbot-container"
      id="chatgpt"
    >
      <div class="chat-window">
        <div class="chat-header">
          <div class="title">
            <!-- <img src="/images/ui/menu2.svg" /> -->
          </div>
          <div class="logo">
            <img src="/images/header/logo.svg" />
          </div>
          <div @click="closeChatGpt()" class="close-button">
            <img src="/images/header/exit.png" />
          </div>
        </div>
        <div v-if="messages.length > 0" class="chat-body-container">
          <div v-if="messages.length > 0" class="chat-body">
            <div
              v-for="(message, index) in messages"
              :key="index"
              :class="{
                'user-message': message.role === 'user',
                'bot-message': message.role === 'assistant',
              }"
            >
              {{ message.content }}
            </div>
            <div v-if="isResponseLoading" class="bot-message">
              <div>Loading...</div>
            </div>
          </div>
        </div>
        <div v-if="messages.length === 0" class="chat-body-container">
          <div class="chat-topic-title-icon">
            <img src="/images/ui/bot.svg" />
          </div>
          <div class="chat-topic-title">
            {{ chatCategoryDetails.title }}
          </div>
          <div class="chat-topic-description">
            {{ chatCategoryDetails.description }}
          </div>
          <div class="chat-prompt-container">
            <div
              class="chat-prompt-item"
              v-for="(suggestion, index) in promptSuggestions"
              :key="index"
              @click="askChatGPT(suggestion.text)"
            >
              <img :src="`/images/ui/${suggestion.icon}`" />
              <div class="prompt">{{ suggestion.text }}</div>
            </div>
          </div>
        </div>
        <div class="chat-footer">
          <input
            type="text"
            class="message-input"
            v-model="newMessage"
            @keyup.enter="sendMessage"
            :placeholder="`Message ${chatCategoryDetails.title}...`"
          />
          <button class="send-button" id="send-btn" @click="sendMessage()">
            <span class="arrow-up">&#8593;</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  // reactive,
  ref,
} from "vue";
import { openai } from "../utils/openai.utils.js";
import { getSelectedLanguage } from "../../aframe/utils/local-storage.utils";
import {
  // transitionTo,
  getChatMetaData,
} from "../utils/aframe.utils";

export default {
  props: {
    category: {
      type: String,
      default: "home",
    },
  },
  data: () => ({
    messages: [
      // {
      //   role: "user",
      //   content: "What is the capital of India?",
      // },
      // {
      //   role: "assistant",
      //   content: "The capital of India is New Delhi",
      // },
      // {
      //   role: "user",
      //   content: "What is the capital of Thailand?",
      // },
      // {
      //   role: "assistant",
      //   content: "The capital of India is Bangkok",
      // },
      // {
      //   role: "user",
      //   content:
      //     "The capital of India is New Delhi. The capital of India is New Delhi. ",
      // },
      // {
      //   role: "assistant",
      //   content: "The capital of India is New Delhi",
      // },
      // {
      //   role: "user",
      //   content:
      //     ". The capital of India is New Delhi. The capital of India is New Delhi",
      // },
      // {
      //   role: "assistant",
      //   content:
      //     "The capital of India is Bangkok. The capital of India is Bangkok",
      // },
      // {
      //   role: "user",
      //   content:
      //     "What is the capital of India? The capital of India is Bangkok",
      // },
      // {
      //   role: "assistant",
      //   content:
      //     "The capital of India is New Delhi. The capital of India is New Delhi. The capital of India is New Delhi. The capital of India is New Delhi",
      // },
      // {
      //   role: "user",
      //   content:
      //     "What is the capital of Thailand?. What is the capital of Thailand?",
      // },
      // {
      //   role: "assistant",
      //   content: "The capital of India is Bangkok",
      // },
    ],
    newMessage: ref(null),
    chatCategoryDetails: {
      title: "",
      description: "",
    },
    promptSuggestions: [],
    selectedLanguage: getSelectedLanguage(),
    isResponseLoading: false,
    systemPrompt: "",
    isChatWindowActive: false,
  }),
  methods: {
    sendMessage() {
      if (this.newMessage.trim() !== "") {
        this.isResponseLoading = true;
        const prompt = {
          role: "user",
          content: this.newMessage,
        };
        this.messages.push(prompt);
        this.newMessage = null;
        this.getChatGptResponse(prompt);
      }
    },
    async getChatGptResponse(prompt) {
      try {
        const chatCompletion = await openai.createChatCompletion({
          messages:
            this.messages.length === 0
              ? [
                  {
                    role: "system",
                    content: this.systemPrompt,
                  },
                  { ...prompt },
                ]
              : [{ ...prompt }],
          model: "gpt-3.5-turbo",
          temperature: 0.7,
          top_p: 0.3,
          n: 1,
          // stream: true,
        });

        this.messages.push(chatCompletion.data.choices[0].message);
        this.isResponseLoading = false;
      } catch (error) {
        console.error("ERROR: SOMETHING WENT WRONG", error);
        this.isResponseLoading = false;
      }
    },
    askChatGPT(text) {
      this.isResponseLoading = true;
      const prompt = {
        role: "user",
        content: text,
      };
      this.messages.push(prompt);
      this.getChatGptResponse(prompt);
    },
    openChatGpt() {
      this.isChatWindowActive = true;
      document.body.classList.add("chatbot-container-active");
      const Div = document.createElement("div");
      Div.className = "blurr-bg";
      const Blurrbg = document.querySelectorAll(".blurr-bg");
      if (Blurrbg.length === 0) {
        const x = document.querySelector(".has-vue-page");
        if (x) x.prepend(Div);
      }
    },
    closeChatGpt() {
      this.isChatWindowActive = false;
      document.body.classList.remove("chatbot-container-active");
      const x = document.querySelector(".blurr-bg");
      if (x) x.remove();

      this.newMessage = ref(null);
      this.messages = [];
    },
  },
  async created() {
    // console.log("Category: ", this.category);
    const metadata = await getChatMetaData();
    const categoryDetails = metadata[this.category];
    if (categoryDetails) {
      this.chatCategoryDetails.title = categoryDetails.title;
      this.chatCategoryDetails.description = categoryDetails.description;
      this.promptSuggestions = categoryDetails.prompts;
      this.systemPrompt = categoryDetails.systemPrompt;
    }
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.chatgpt-component {
  .chat-btn {
    position: fixed;
    bottom: 80px;
    right: -600px;
    img {
      width: 280px;
    }
  }
  &.visible {
    .chat-btn {
      right: 10px;
    }
  }
  &.animated {
    .chat-btn {
      transition: all 1.25s;
    }
  }
}
// .chat-btn {
//   position: fixed;
//   bottom: 80px;
//   right: 10px;
//   img {
//     width: 280px;
//   }
// }
.chatbot-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 100vh;
  left: 0;
  width: 100vw;
  width: 100%;
  height: 100%;
  pointer-events: none;
  visibility: hidden;
  z-index: 40;
  transition: all 1s;
  &.visible {
    visibility: visible;
    top: 0;
  }
  // &.animated {
  //   transition: all 1s;
  // }
  .chat-window {
    background: radial-gradient(ellipse at center, #2a4a7b 0%, #132140 100%);

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
    vertical-align: center;
    pointer-events: all;
    // background: transparent;
    display: flex;
    flex-direction: column;
    height: 100%;
    .chat-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      background-color: #13203d;
      color: white;

      .title {
        img {
          width: 30px;
        }
      }
      .logo {
        img {
          width: 200px;
        }
      }
      .close-button {
        display: flex;
        justify-content: center;
        align-items: center;
        // position: absolute;
        // top: -14px;
        // right: -14px;
        width: 30px;
        height: 30px;
        text-align: center;
        background-color: transparent;
        cursor: pointer;
        color: black;

        img {
          width: 100%;
        }
      }
    }
    .chat-body-container {
      flex: 1;
      padding: 15px;
      overflow-y: auto;
      background-color: transparent;

      .chat-body {
        background-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .bot-message {
          padding: 10px 15px;
          margin: 10px 0;
          width: 90%;
          font-size: 14px;
          background: transparent;
          text-align: left;
          color: white;
          letter-spacing: 0.5px;
        }

        .user-message {
          padding: 10px 15px;
          margin: 10px 0;
          border-radius: 20px;
          max-width: 70%;
          font-size: 14px;
          background-color: #4e5c77;
          color: white;
          // text-align: right;
          margin-left: auto;
          letter-spacing: 0.5px;
        }
      }
      .chat-topic-title-icon {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        img {
          width: 60px;
          height: 60px;
        }
      }
      .chat-topic-title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
        margin-top: 40px;
        text-align: center;
        color: white;
      }
      .chat-topic-description {
        font-size: 16px;
        margin-bottom: 10px;
        text-align: center;
        color: white;
        letter-spacing: 0.5;
        margin-bottom: 80px;
      }
      .chat-prompt-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        justify-content: center;

        .chat-prompt-item {
          display: flex;
          align-items: start;
          padding: 10px;
          border-radius: 8px;
          background-color: transparent;
          color: white;
          margin: 5px;
          width: 140px;
          cursor: pointer;
          border: 0.99px solid #325298;
          img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
          &:hover {
            background-color: #4e5c77; /* Slight hover effect */
          }
          .prompt {
            line-height: 1;
            min-height: 50px;
            font-family: sans-serif;
            font-size: smaller;
            letter-spacing: 1px;
          }
        }
      }
    }
    .chat-footer {
      display: flex;
      padding: 10px;
      // background-color: #eee;
      background: #13203d;
      align-items: center;
      padding: 10px 20px;

      input {
        // flex: 1;
        padding: 10px;
        border: none;
        // border-radius: 5px;
        // font-size: 16px;
        // width: calc(100% - 22px);
        background-color: #0b132b;
        border-radius: 50px;
        color: white;
        font-size: 16px;
        flex-grow: 1;
        outline: none;
      }
      .send-button {
        background-color: #2d3e50; /* Slightly lighter blue */
        border: none;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 10px;
        .arrow-up {
          color: white;
          font-size: 20px;
        }
      }
      &:hover {
        background-color: #4e5c77; /* Slight hover effect */
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .chat-window {
    width: 60% !important;
  }
}
@media screen and (max-width: 767px) {
  .chat-window {
    width: 80% !important;
  }
}
@media screen and (max-width: 599px) {
  .chat-window {
    width: 100% !important;
  }

  .chat-btn {
    bottom: 10px !important;
  }
}
@media screen and (max-width: 400px) {
  .chat-window {
    width: 100% !important;
  }
  .chat-btn {
    bottom: 10px !important;
  }
}
</style>
