var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isModalOpen
    ? _c("div", { staticClass: "modal_section", class: _vm.status }, [
        _c(
          "div",
          { staticClass: "close_modal_btn", on: { click: _vm.onModalClose } },
          [_c("img", { attrs: { src: "/images/ui/exit-button.png" } })]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal_content" }, [
          _c("p", { domProps: { innerHTML: _vm._s(_vm.modalText) } }),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }