var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("img", {
    class: { set_left_position: _vm.isLeftVRButton },
    attrs: { id: "enter-vr-button", src: "/images/ui/vr-button.png" },
    on: {
      click: function ($event) {
        return _vm.onClick()
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }