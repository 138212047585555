import axios from "axios";
import {
  webstore,
  getUid,
  getMsisdn,
  getDomain,
  getAuthEndpoint,
  getLoginCode,
} from "../helpers/auth-helpers";
import { noAuthRequired } from "./redirect.utils";
import { sendOnAuthSuccess, sendOnAuthFail } from "./dataCollection.utils";
import { setMixpanelUser } from "../../mixpanel";

export const checkAuth = async () => {
  const uid = getUid();
  const msisdn = getMsisdn();
  const loginCode = getLoginCode();
  const domain = getDomain();
  const url = getAuthEndpoint();
  const token = webstore.getProperty("token");

  // If authentication not needed
  if (noAuthRequired()) return true;

  // If token already exist and is in LocalStorage
  if (token && token.length > 0 && !uid) return true;

  if (loginCode) url.searchParams.append("loginCode", loginCode);
  else if (msisdn) url.searchParams.append("msisdn", msisdn);
  else if (uid) url.searchParams.append("uid", uid);
  else {
    sendOnAuthFail({ uid, msisdn, err: "no_param_supplied" });
    return false;
  }

  url.searchParams.append("domain", domain);

  try {
    const response = await axios.get(url.href);
    const data = response.data;
    if (data.success) {
      const {
        rockmanId,
        token,
        msisdn: mobileNumber,
        startDate,
        renewalDate,
      } = data.payload;

      webstore.add(
        { token, msisdn: mobileNumber, startDate, renewalDate },
        rockmanId
      );
      sendOnAuthSuccess({ uid, msisdn });
      setMixpanelUser({ id: rockmanId });
      return true;
    } else {
      const { error_reason: err } = response.data;
      sendOnAuthFail({ uid, msisdn, err });
      return false;
    }
  } catch (e) {
    console.log(e);
    const { error_reason: err } = e.response.data;
    sendOnAuthFail({ uid, msisdn, err });
    throw new Error("error in auth");
  }
};
