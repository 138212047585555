import { environmentConfig } from "./environments/environment";

import "./aframe/index";
import "./html/style.scss";
import "./html/transition.scss";
import "./vue/index";
import "./mixpanel";

if (environmentConfig.enableSentry) {
  require("./sentry");
}
