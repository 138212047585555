<template>
  <div class="generic-menu">
    <Background
      src="https://xracademy-contents.s3.eu-west-1.amazonaws.com/data/mobile-backgrounds/biolab.jpg"
    ></Background>
    <div v-if="!asset" class="content">
      <Carousel :items="carouselItems"></Carousel>
    </div>
    <div v-else class="content">
      <ModelMenu :items="carouselItems"></ModelMenu>
    </div>
    <ChatGpt ref="chatgptComponentChild" :category="category"></ChatGpt>
  </div>
</template>

<script>
import Background from "../components/background.component.vue";
import Carousel from "../components/carousel.component.vue";
import ModelMenu from "../components/model-menu.component.vue";
import { transitionTo } from "../utils/aframe.utils";
import { Endpoints } from "../../endpoints";
import { sendPageView } from "../utils/dataCollection.utils";
import ChatGpt from "../components/chatgpt.component.vue";

export default {
  components: {
    Background,
    Carousel,
    ModelMenu,
    ChatGpt,
  },
  props: {
    submenu: {
      type: String,
      default: "",
    },
    category: {
      type: String,
      default: "",
    },
    asset: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    carouselItems: [],
    title: null,
  }),
  async created() {
    const { menu, title } = await document
      .querySelector("a-scene")
      .systems.data.fetch(
        Endpoints.menus(`${this.category}`, `${this.submenu}`, `${this.asset}`)
      );
    this.carouselItems = menu.map((button) => ({
      image: button.thumbnail,
      scene: button.scene ? button.scene.split("/") : null,
      title: button.thumbnail_text,
    }));
    this.title = title;
  },
  mounted() {
    sendPageView();
    const elChatGpt = this.$refs.chatgptComponentChild.$el;
    if (elChatGpt) {
      elChatGpt.classList.add("animated");
      elChatGpt.classList.add("visible");
    }
  },
  methods: {
    checkDragging() {
      const { dragging } = this.$refs.carousel;
      setTimeout(() => (this.dragging = dragging), 100);
    },
    navigateToMainMenu() {
      transitionTo([""]);
    },
  },
};
</script>

<style scoped lang="scss">
.generic-menu {
  min-height: 100%;
  height: 100%;

  background-attachment: fixed;
  background-position: center center;
  background-size: auto 100%;

  .content {
    position: fixed;
    top: 53%;
    transform: translateY(-50%);
    width: 100%;

    .back-button {
      width: 64px;
      height: 24px;
      transform: translate(5px, -33px);
    }

    .category {
      color: white;
      text-align: center;
      font-size: 18px;
    }

    img {
      margin: 0 10px;
      width: calc(100% - 20px);
    }
    @media screen and (max-width: 900px) and (orientation: landscape) {
      position: relative;
    }
  }
}
</style>
