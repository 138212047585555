import { config } from "./domain-config";
import { Endpoints } from "../../endpoints";
import {
  PRODUCT_MOBIO_VR,
  COMPANY_SAM_MEDIA,
  COMPANY_TREND_TECH,
} from "./page-constants";
import { getSelectedLanguage } from "../../aframe/utils/local-storage.utils";

const tncObj = {
  th: {
    name: "Sam Media Sdn Bhd",
    email: "th@content-clubs.com",
  },
  my: {
    name: "Sam Media Sdn Bhd",
    email: "mycs@content-clubs.com",
    addressLine1: "Suite 2, Level 11, Menara Ilham",
    addressLine2: "NO. 8 Jln Binjai, 50450 KL",
  },
  sg: {
    name: "Sam Media Limited",
    email: "sg-xracademy@cchelp.co",
    addressLine1: "Van Diemenstraat 356",
    addressLine2: "1013 CR Amsterdam",
  },
  demo: {
    email: "support@xr-academy.com",
    addressLine1: "Van Diemenstraat 356",
    addressLine2: "1013 CR Amsterdam The Netherlands",
  },
  de: {
    email: "deservices@cchelp.co",
    addressLine1: "Van Diemenstraat 356",
    addressLine2: "1013 CR Amsterdam",
  },
  umobile: {
    name: "Sam Media Sdn Bhd",
    email: "mycs@content-clubs.com",
    addressLine1: "Suite 2, Level 11, Menara Ilham",
    addressLine2: "NO. 8 Jln Binjai, 50450 KL",
  },
};

const terms = {
  [COMPANY_TREND_TECH]: {
    companyName: "TrendTech",
    ownerName: "TrendTech Ltd",
    ownerAddressLine1:
      "27 Old Gloucester Street, London WC1N 3AX, United Kingdom",
    ownerAddressLine2: "Old Gloucester London WC1N 3AX",
    owneremail: "uk.support@trend-tech.co.uk",
    lastUpdateDate: "July 23, 2021",
  },
  [COMPANY_SAM_MEDIA]: {
    companyName: "Sam Media",
    ownerName:
      (tncObj[config.subdomain] && tncObj[config.subdomain].name) ||
      "Sam Media BV",
    ownerAddressLine1:
      (tncObj[config.subdomain] && tncObj[config.subdomain].addressLine1) ||
      "Van Diemenstraat 140",
    ownerAddressLine2:
      (tncObj[config.subdomain] && tncObj[config.subdomain].addressLine2) ||
      "1013 CN Amsterdam",
    owneremail:
      (tncObj[config.subdomain] && tncObj[config.subdomain].email) ||
      "info@sam-media.com",
    lastUpdateDate: "May 08, 2020",
  },
};

// TODO: ALSO TAKE INTO ACCOUNT THE SUBDOMAIN HERE, TO LAUNCH MOBIOVR WITH SAM MEDIA FOR SOME OTHER CONNECTION
export const getTermsData = () =>
  config.productName === PRODUCT_MOBIO_VR
    ? terms[COMPANY_TREND_TECH]
    : terms[COMPANY_SAM_MEDIA];

export const getTermsText = async () => {
  const x = await document
    .querySelector("a-scene")
    .systems.data.fetch(Endpoints.termsPage());

  const lang = getSelectedLanguage();
  const termsPageLang = lang || "en";

  return x[termsPageLang];
};

export const getCreditCardTermsText = async () => {
  const x = await document
    .querySelector("a-scene")
    .systems.data.fetch(Endpoints.creditCardTermsPage());

  const lang = getSelectedLanguage();
  const termsPageLang = lang || "en";

  return x[termsPageLang];
};
