import { Endpoints } from "../../endpoints";

export const getFaqText = async () => {
  const x = await document
    .querySelector("a-scene")
    .systems.data.fetch(Endpoints.faqPage());

  const faqPageLang = "en";

  return x[faqPageLang];
};
