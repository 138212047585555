var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "terms-and-conditions" } }, [
    _vm.pageText !== null && !_vm.isSubdomainPL
      ? _c("div", [
          _c("div", { staticClass: "terms-conditions-head" }, [
            _c("h1", { staticClass: "text_1" }, [
              _vm._v(_vm._s(_vm.pageText.text_1)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "terms-condition-container" }, [
            _vm.isSubdomainPE
              ? _c("div", { staticClass: "one_line_col" }, [
                  _c("p", { staticClass: "pe_text_217" }, [
                    _vm._v(_vm._s(_vm.pageText.pe_text_217)),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("p", { staticClass: "pe_text_218" }, [
                    _vm._v(_vm._s(_vm.pageText.pe_text_218)),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("p", { staticClass: "pe_text_219" }, [
                    _vm._v(_vm._s(_vm.pageText.pe_text_219)),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("p", { staticClass: "pe_text_220" }, [
                    _vm._v(_vm._s(_vm.pageText.pe_text_220)),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("p", { staticClass: "pe_text_221" }, [
                    _vm._v(_vm._s(_vm.pageText.pe_text_221)),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "privacy-policy-container" }, [
              _c("h1", { staticClass: "text_2" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_2) + "\n          "
                ),
                _c("strong", [_vm._v(_vm._s(_vm.termsData.companyName))]),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text_3" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_3) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("p", { staticClass: "text_4" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_4) + "\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "one_line_col" }, [
              _c(
                "h2",
                { staticClass: "text_5", attrs: { id: "owner_of_the_data" } },
                [_vm._v(_vm._s(_vm.pageText.text_5))]
              ),
              _vm._v(" "),
              _c("div", [
                _c("p", [_vm._v(_vm._s(_vm.termsData.ownerName))]),
                _vm._v(" "),
                !_vm.isSubdomainTH
                  ? _c("div", { staticClass: "address-para" }, [
                      _c("p", [
                        _vm._v(_vm._s(_vm.termsData.ownerAddressLine1)),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(_vm._s(_vm.termsData.ownerAddressLine2)),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("b", { staticClass: "text_6" }, [
                  _vm._v(_vm._s(_vm.pageText.text_6)),
                ]),
                _vm._v(_vm._s(_vm.termsData.owneremail) + "\n        "),
              ]),
            ]),
            _vm._v(" "),
            _vm.isSubdomainZA
              ? _c("div", { staticClass: "one_line_col" }, [
                  _c(
                    "h2",
                    { staticClass: "text_7", attrs: { id: "types_of_data" } },
                    [_vm._v(_vm._s(_vm.pageText.text_7))]
                  ),
                  _vm._v(" "),
                  _vm._m(0),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "one_line_col" }, [
              _c(
                "h2",
                { staticClass: "text_8", attrs: { id: "types_of_data" } },
                [_vm._v(_vm._s(_vm.pageText.text_8))]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "text_9" }, [
                _vm._v(_vm._s(_vm.pageText.text_9)),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("p", { staticClass: "text_10" }, [
                _vm._v(_vm._s(_vm.pageText.text_10)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text_11" }, [
                _vm._v(_vm._s(_vm.pageText.text_11)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "one_line_col" }, [
              _c("h2", { staticClass: "text_12" }, [
                _vm._v(_vm._s(_vm.pageText.text_12)),
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "text_13" }, [
                _vm._v(_vm._s(_vm.pageText.text_13)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text_14" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_14) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "text_15" }, [
                _vm._v(_vm._s(_vm.pageText.text_15)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text_16" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_16) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("ul", [
                _c("li", { staticClass: "text_17" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.pageText.text_17) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_18" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.pageText.text_18) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_19" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.pageText.text_19) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_20" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.pageText.text_20) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_21" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.pageText.text_21) +
                      "\n          "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text_22" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_22) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "text_23" }, [
                _vm._v(_vm._s(_vm.pageText.text_23)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text_24" }, [
                _vm._v("\n          " + _vm._s(_vm.pageText.text_24)),
                _c("br"),
                _c("br"),
                _vm._v(" "),
                _c("span", { staticClass: "text_25" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.pageText.text_25) +
                      "\n          "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text_26" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_26) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "text_27" }, [
                _vm._v(_vm._s(_vm.pageText.text_27)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text_28" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_28) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text_29" }, [
                _vm._v(_vm._s(_vm.pageText.text_29)),
              ]),
              _vm._v(" "),
              _c("ul", [
                _c("li", { staticClass: "text_30" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.pageText.text_30) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_31" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.pageText.text_31) +
                      "\n          "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text_32" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_32) + "\n          "
                ),
                _c("br"),
                _c("br"),
                _vm._v(" "),
                _c("span", { staticClass: "text_33" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.pageText.text_33) +
                      "\n          "
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "one_line_col" }, [
              _c(
                "h2",
                { staticClass: "text_34", attrs: { id: "use_collected_data" } },
                [
                  _vm._v(
                    "\n          " + _vm._s(_vm.pageText.text_34) + "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "text_35" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_35) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text_36" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_36) + "\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "one_line_col" }, [
              _c("h2", { staticClass: "detail_head text_37" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_37) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text_38" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_38) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("ul", { staticClass: "for_boxes" }, [
                _c("li", [
                  _c("div", { staticClass: "box_primary" }, [
                    _c("h3", { staticClass: "box-head text_39" }, [
                      _vm._v(_vm._s(_vm.pageText.text_39)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "expand-content" }, [
                      _c("p", { staticClass: "text_40" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_40) +
                            "\n                  "
                        ),
                        _c("br"),
                        _vm._v(" "),
                        _c("span", { staticClass: "text_41" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.pageText.text_41) +
                              "\n                  "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "text_42",
                            attrs: {
                              href: "http://optout.networkadvertising.org/?c=1",
                            },
                          },
                          [_vm._v(_vm._s(_vm.pageText.text_42))]
                        ),
                        _vm._v(".\n                "),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("strong", { staticClass: "text_43" }, [
                          _vm._v(_vm._s(_vm.pageText.text_43)),
                        ]),
                        _vm._v(".\n                "),
                      ]),
                      _vm._v(" "),
                      _c("h4", { staticClass: "text_44" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_44) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "wrap" }, [
                        _c("p", { staticClass: "text_45" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.pageText.text_45) +
                              "\n                  "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "text_46" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.pageText.text_46) +
                              "\n                    "
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "text_47",
                              attrs: {
                                href: "https://adssettings.google.com/authenticated",
                              },
                            },
                            [_vm._v(_vm._s(_vm.pageText.text_47))]
                          ),
                          _vm._v(".\n                  "),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text_48" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_48) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text_49" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_49) +
                            "\n                  "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "text_50",
                            attrs: {
                              href: "https://policies.google.com/privacy",
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                          [_vm._v(_vm._s(_vm.pageText.text_50))]
                        ),
                        _vm._v("\n                  –\n                  "),
                        _c(
                          "a",
                          {
                            staticClass: "text_51",
                            attrs: {
                              href: "https://adssettings.google.com/authenticated",
                            },
                          },
                          [_vm._v(_vm._s(_vm.pageText.text_51))]
                        ),
                        _vm._v(".\n                  "),
                        _c("span", { staticClass: "text_52" }, [
                          _vm._v(_vm._s(_vm.pageText.text_52) + "."),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("div", { staticClass: "box_primary" }, [
                    _c("h3", { staticClass: "box-head text_53" }, [
                      _vm._v(_vm._s(_vm.pageText.text_53)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "expand-content" }, [
                      _c("p", { staticClass: "text_54" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_54) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("h4", { staticClass: "text_55" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_55) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "wrap" }, [
                        _c("p", { staticClass: "text_56" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.pageText.text_56) +
                              "\n                  "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text_57" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_57) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text_58" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_58) +
                            "\n                  "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "text_59",
                            attrs: {
                              href: "https://policies.google.com/privacy",
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                          [_vm._v(_vm._s(_vm.pageText.text_59))]
                        ),
                        _vm._v("\n                  –\n                  "),
                        _c(
                          "a",
                          {
                            staticClass: "text_60",
                            attrs: {
                              href: "https://tools.google.com/dlpage/gaoptout?hl=en",
                              onclick:
                                "return tryFunc('tryGaOptOut',\n                  {href:'https://tools.google.com/dlpage/gaoptout?hl=en'})",
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(_vm.pageText.text_60))]
                        ),
                        _vm._v(".\n                  "),
                        _c("span", { staticClass: "text_61" }, [
                          _vm._v(" " + _vm._s(_vm.pageText.text_61) + " "),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("h4", { staticClass: "text_62" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_62) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "wrap" }, [
                        _c("p", { staticClass: "text_63" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.pageText.text_63) +
                              "\n                  "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text_64" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_64) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("h4", { staticClass: "text_65" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_65) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "wrap" }, [
                        _c("p", { staticClass: "text_66" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.pageText.text_66)
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c("span", { staticClass: "text_67" }, [
                            _vm._v(
                              "\n                      " +
                                _vm._s(_vm.pageText.text_67) +
                                "\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c("span", { staticClass: "text_68" }, [
                            _vm._v(
                              "\n                      " +
                                _vm._s(_vm.pageText.text_68) +
                                "\n                    "
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text_214" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_214) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text_69" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_69) +
                            "\n                  "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "text_70",
                            attrs: {
                              href: "https://policies.google.com/privacy",
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                          [_vm._v(_vm._s(_vm.pageText.text_70))]
                        ),
                        _vm._v("\n                  –\n                  "),
                        _c(
                          "a",
                          {
                            staticClass: "text_71",
                            attrs: {
                              href: "https://tools.google.com/dlpage/gaoptout?hl=en",
                              onclick:
                                "return tryFunc('tryGaOptOut',\n                  {href:'https://tools.google.com/dlpage/gaoptout?hl=en'})",
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(_vm.pageText.text_71))]
                        ),
                        _vm._v(".\n                  "),
                        _c("span", { staticClass: "text_72" }, [
                          _vm._v(" " + _vm._s(_vm.pageText.text_72) + " "),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("h4", { staticClass: "text_73" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_73) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "wrap" }, [
                        _c("p", { staticClass: "text_74" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.pageText.text_74) +
                              "\n                  "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text_75" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_75) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text_76" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_76) +
                            "\n                  "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "text_77",
                            attrs: {
                              href: "https://policies.google.com/privacy",
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                          [_vm._v(_vm._s(_vm.pageText.text_77))]
                        ),
                        _vm._v(".\n                  "),
                        _c("span", { staticClass: "text_78" }, [
                          _vm._v(" " + _vm._s(_vm.pageText.text_78)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("h4", { staticClass: "text_79" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_79) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "wrap" }, [
                        _c("p", { staticClass: "text_80" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.pageText.text_80) +
                              "\n                  "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "text_81" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.pageText.text_81) +
                              "\n                    "
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "text_82",
                              attrs: {
                                href: "https://adssettings.google.com/authenticated",
                              },
                            },
                            [_vm._v(_vm._s(_vm.pageText.text_822))]
                          ),
                          _vm._v(".\n                  "),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text_83" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_83) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text_84" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_84) +
                            "\n                  "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "text_85",
                            attrs: {
                              href: "https://policies.google.com/privacy",
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                          [_vm._v(_vm._s(_vm.pageText.text_85))]
                        ),
                        _vm._v("\n                  –\n                  "),
                        _c(
                          "a",
                          {
                            staticClass: "text_86",
                            attrs: {
                              href: "https://tools.google.com/dlpage/gaoptout",
                              onclick:
                                "javascript:return tryFunc('tryGaOptOut',\n                  {href:'https://tools.google.com/dlpage/gaoptout'})",
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(_vm.pageText.text_86))]
                        ),
                        _vm._v(".\n                  "),
                        _c("span", { staticClass: "text_87" }, [
                          _vm._v(" " + _vm._s(_vm.pageText.text_87) + " "),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("h4", { staticClass: "text_88" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_88) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "wrap" }, [
                        _c("p", { staticClass: "text_89" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.pageText.text_89) +
                              "\n                  "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "text_90" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.pageText.text_90) +
                              "\n                    "
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "text_91",
                              attrs: {
                                href: "https://adssettings.google.com/authenticated",
                              },
                            },
                            [_vm._v(_vm._s(_vm.pageText.text_91))]
                          ),
                          _vm._v(".\n                  "),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text_92" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_92) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text_93" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_93) +
                            "\n                  "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "text_94",
                            attrs: {
                              href: "https://policies.google.com/privacy",
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                          [_vm._v(_vm._s(_vm.pageText.text_94))]
                        ),
                        _vm._v("\n                  –\n                  "),
                        _c(
                          "a",
                          {
                            staticClass: "text_95",
                            attrs: {
                              href: "https://tools.google.com/dlpage/gaoptout",
                              onclick:
                                "javascript:return tryFunc('tryGaOptOut',\n                  {href:'https://tools.google.com/dlpage/gaoptout'})",
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(_vm.pageText.text_95))]
                        ),
                        _vm._v(".\n                  "),
                        _c("span", { staticClass: "text_96" }, [
                          _vm._v(" " + _vm._s(_vm.pageText.text_96) + " "),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("div", { staticClass: "box_primary" }, [
                    _c("h3", { staticClass: "box-head text_97" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.pageText.text_97) +
                          "\n              "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "expand-content text_98" }, [
                      _c("p", [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_98) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("h4", { staticClass: "text_99" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_99) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "wrap text_100" }, [
                        _c("p", [_vm._v(_vm._s(_vm.pageText.text_100))]),
                        _vm._v(" "),
                        _c("p", { staticClass: "text_101" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.pageText.text_101) +
                              "\n                  "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text_102" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_102) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text_103" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_103) +
                            "\n                  "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "text_104",
                            attrs: {
                              href: "https://policies.google.com/privacy",
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                          [_vm._v(_vm._s(_vm.pageText.text_104))]
                        ),
                        _vm._v(".\n                  "),
                        _c("span", { staticClass: "text_105" }, [
                          _vm._v(" " + _vm._s(_vm.pageText.text_105) + " "),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("div", { staticClass: "box_primary" }, [
                    _c("h3", { staticClass: "box-head text_106" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.pageText.text_106) +
                          "\n              "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "expand-content" }, [
                      _c("p", { staticClass: "text_107" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_107) +
                            "\n                  "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "text_108",
                            attrs: {
                              href: "http://optout.networkadvertising.org/?c=1",
                            },
                          },
                          [_vm._v(_vm._s(_vm.pageText.text_108))]
                        ),
                        _vm._v(".\n                "),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _c("strong", { staticClass: "text_109" }, [
                          _vm._v(_vm._s(_vm.pageText.text_109)),
                        ]),
                        _vm._v(".\n                "),
                      ]),
                      _vm._v(" "),
                      _c("h4", { staticClass: "text_110" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_110) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "wrap" }, [
                        _c("p", { staticClass: "text_111" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.pageText.text_111) +
                              "\n                  "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text_112" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_112) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text_113" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_113) +
                            "\n                  "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "text_114",
                            attrs: {
                              href: "https://policies.google.com/privacy",
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                          [_vm._v(_vm._s(_vm.pageText.text_114))]
                        ),
                        _vm._v("\n                  –\n                  "),
                        _c(
                          "a",
                          {
                            staticClass: "text_115",
                            attrs: {
                              href: "https://adssettings.google.com/authenticated#display_optout",
                            },
                          },
                          [_vm._v(_vm._s(_vm.pageText.text_115))]
                        ),
                        _vm._v(".\n                  "),
                        _c("span", { staticClass: "text_116" }, [
                          _vm._v(" " + _vm._s(_vm.pageText.text_116) + " "),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("h4", { staticClass: "text_117" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_117) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "wrap" }, [
                        _c("p", { staticClass: "text_118" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.pageText.text_118) +
                              "\n                  "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "text_119" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.pageText.text_119) +
                              "\n                    "
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "text_120",
                              attrs: {
                                href: "https://adssettings.google.com/authenticated",
                              },
                            },
                            [_vm._v(_vm._s(_vm.pageText.text_120))]
                          ),
                          _vm._v(".\n                  "),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text_121" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_121) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text_122" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_122) +
                            "\n                  "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "text_123",
                            attrs: {
                              href: "https://policies.google.com/privacy",
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                          [_vm._v(_vm._s(_vm.pageText.text_123))]
                        ),
                        _vm._v("\n                  –\n                  "),
                        _c(
                          "a",
                          {
                            staticClass: "text_124",
                            attrs: {
                              href: "https://adssettings.google.com/authenticated#display_optout",
                            },
                          },
                          [_vm._v(_vm._s(_vm.pageText.text_124))]
                        ),
                        _vm._v(".\n                  "),
                        _c("span", { staticClass: "text_125" }, [
                          _vm._v(_vm._s(_vm.pageText.text_125) + " "),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("h4", { staticClass: "text_126" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_126) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "wrap" }, [
                        _c("p", { staticClass: "text_127" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.pageText.text_127) +
                              "\n                  "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text_128" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_128) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text_129" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_129) +
                            "\n                  "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "text_130",
                            attrs: {
                              href: "https://policies.google.com/privacy",
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                          [_vm._v(_vm._s(_vm.pageText.text_130))]
                        ),
                        _vm._v("\n                  –\n                  "),
                        _c(
                          "a",
                          {
                            staticClass: "text_131",
                            attrs: {
                              href: "https://adssettings.google.com/authenticated#display_optout",
                            },
                          },
                          [_vm._v(_vm._s(_vm.pageText.text_131))]
                        ),
                        _vm._v(".\n                  "),
                        _c("span", { staticClass: "text_132" }, [
                          _vm._v(" " + _vm._s(_vm.pageText.text_132) + " "),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("h4", { staticClass: "text_133" }, [
                        _vm._v(_vm._s(_vm.pageText.text_133)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "wrap" }, [
                        _c("p", { staticClass: "text_134" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.pageText.text_134) +
                              "\n                  "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text_135" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_135) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text_136" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_1366) +
                            "\n                  "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "text_137",
                            attrs: {
                              href: "https://www.facebook.com/about/privacy/",
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                          [_vm._v(_vm._s(_vm.pageText.text_137))]
                        ),
                        _vm._v("\n                  –\n                  "),
                        _c(
                          "a",
                          {
                            staticClass: "text_137",
                            attrs: {
                              href: "https://www.aboutads.info/choices/",
                            },
                          },
                          [_vm._v(_vm._s(_vm.pageText.text_138))]
                        ),
                        _vm._v(".\n                  "),
                        _c("span", { staticClass: "text_215" }, [
                          _vm._v(" " + _vm._s(_vm.pageText.text_215)),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("div", { staticClass: "box_primary" }, [
                    _c("h3", { staticClass: "box-head text_138" }, [
                      _vm._v(_vm._s(_vm.pageText.text_138)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "expand-content" }, [
                      _c("p", { staticClass: "text_139" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_139) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("h4", { staticClass: "text_140" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_140) +
                            "\n                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "wrap" }, [
                        _c("p", { staticClass: "text_141" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.pageText.text_141) +
                              "\n                  "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text_216" }, [
                        _vm._v(_vm._s(_vm.pageText.text_216)),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "text_142" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.pageText.text_142) +
                            "\n                  "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "text_143",
                            attrs: {
                              href: "https://policies.google.com/privacy",
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                          [_vm._v(_vm._s(_vm.pageText.text_143))]
                        ),
                        _vm._v(".\n                  "),
                        _c("span", { staticClass: "text_144" }, [
                          _vm._v(" " + _vm._s(_vm.pageText.text_144) + " "),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "one_line_col" }, [
              _c("h2", { attrs: { id: "further_data text_145" } }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_145) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("ul", { staticClass: "for_boxes" }, [
                _c("li", [
                  _c("div", { staticClass: "box_primary" }, [
                    _c("h3", { staticClass: "box-head text_146" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.pageText.text_146) +
                          "\n              "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "expand-content" }, [
                      _c("div", { staticClass: "wrap" }, [
                        _c("p", { staticClass: "text_147" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.pageText.text_147) +
                              "\n                  "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "one_line_col" }, [
              _c("h2", { attrs: { id: "rights_subjects text_148" } }, [
                _vm._v(_vm._s(_vm.pageText.text_148)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text_149" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_149) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text_150" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_150) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("ul", [
                _c("li", [
                  _c("b", { staticClass: "text_151" }, [
                    _vm._v(_vm._s(_vm.pageText.text_151) + " "),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("b", { staticClass: "text_152" }, [
                    _vm._v(_vm._s(_vm.pageText.text_152)),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "text_213" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.pageText.text_213) +
                        "\n            "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("b", { staticClass: "text_153" }, [
                    _vm._v(_vm._s(_vm.pageText.text_153)),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "text_154" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.pageText.text_154) +
                        "\n            "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("b", { staticClass: "text_155" }, [
                    _vm._v(_vm._s(_vm.pageText.text_155)),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "text_156" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.pageText.text_156) +
                        "\n            "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("b", { staticClass: "text_157" }, [
                    _vm._v(_vm._s(_vm.pageText.text_157) + "."),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "text_158" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.pageText.text_158) +
                        "\n            "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("b", { staticClass: "text_159" }, [
                    _vm._v(_vm._s(_vm.pageText.text_159)),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "text_160" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.pageText.text_160) +
                        "\n            "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("b", { staticClass: "text_161" }, [
                    _vm._v(_vm._s(_vm.pageText.text_161)),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "text_162" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.pageText.text_162) +
                        "\n            "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c("b", { staticClass: "text_163" }, [
                    _vm._v(_vm._s(_vm.pageText.text_163)),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "text_164" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.pageText.text_164) +
                        "\n            "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "text_165" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_165) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text_166" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_166) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text_167" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_167) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "text_168" }, [
                _vm._v(_vm._s(_vm.pageText.text_168)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text_169" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_169) + "\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "one_line_col" }, [
              _c(
                "h2",
                { staticClass: "text_170", attrs: { id: "cookie_policy" } },
                [_vm._v(_vm._s(_vm.pageText.text_170))]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "text_171" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.pageText.text_171) +
                    "\n          "
                ),
                _c(
                  "a",
                  {
                    staticClass: "text_172",
                    attrs: {
                      href: "https://www.iubenda.com/privacy-policy/gdpr/98937083/cookie-policy",
                      title: "Cookie Policy",
                    },
                  },
                  [_vm._v(_vm._s(_vm.pageText.text_172))]
                ),
                _vm._v(".\n        "),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "one_line_col" }, [
              _c(
                "h2",
                { staticClass: "text_173", attrs: { id: "rights_subjects" } },
                [_vm._v(_vm._s(_vm.pageText.text_173))]
              ),
              _vm._v(" "),
              _vm._m(1),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "one_line_col" }, [
              _c(
                "h2",
                {
                  staticClass: "text_174",
                  attrs: { id: "further_data_processing_info" },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.pageText.text_174) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("h3", { staticClass: "text_175" }, [
                _vm._v(_vm._s(_vm.pageText.text_175)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text_176" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_176) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "text_177" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_177) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text_178" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_178) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "text_179" }, [
                _vm._v(_vm._s(_vm.pageText.text_179)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text_188" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_188) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "text_180" }, [
                _vm._v(_vm._s(_vm.pageText.text_180)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text_181" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_181) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "text_182" }, [
                _vm._v(_vm._s(_vm.pageText.text_182)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text_183" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_183) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "text_184" }, [
                _vm._v(_vm._s(_vm.pageText.text_184)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text_185" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.pageText.text_185) +
                    "\n          "
                ),
                _c("br"),
                _c("br"),
                _vm._v(" "),
                _c("span", { staticClass: "text_186" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.pageText.text_186) +
                      "\n          "
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "one_line_col" }, [
              _c("div", { staticClass: "defination-box" }, [
                _c(
                  "h3",
                  { staticClass: "text_187", attrs: { id: "legal-head" } },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.pageText.text_187) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "expand-content" }, [
                  _c("h4", { staticClass: "text_189" }, [
                    _vm._v(_vm._s(_vm.pageText.text_189)),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text_190" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.pageText.text_190) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h4", { staticClass: "text_191" }, [
                    _vm._v(_vm._s(_vm.pageText.text_191)),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text_192" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.pageText.text_192) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h4", { staticClass: "text_193" }, [
                    _vm._v(_vm._s(_vm.pageText.text_193)),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text_194" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.pageText.text_194) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h4", { staticClass: "text_195" }, [
                    _vm._v(_vm._s(_vm.pageText.text_195)),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text_196" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.pageText.text_196) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h4", { staticClass: "text_197" }, [
                    _vm._v(_vm._s(_vm.pageText.text_197)),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text_198" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.pageText.text_198) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h4", { staticClass: "text_199" }, [
                    _vm._v(_vm._s(_vm.pageText.text_199)),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text_200" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.pageText.text_200) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h4", { staticClass: "text_201" }, [
                    _vm._v(_vm._s(_vm.pageText.text_201)),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text_202" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.pageText.text_202) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h4", { staticClass: "text_203" }, [
                    _vm._v(_vm._s(_vm.pageText.text_203)),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text_204" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.pageText.text_204) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h4", { staticClass: "text_205" }, [
                    _vm._v(_vm._s(_vm.pageText.text_205)),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text_206" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.pageText.text_206) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h4", { staticClass: "text_207" }, [
                    _vm._v(_vm._s(_vm.pageText.text_207)),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text_208" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.pageText.text_208) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c("h4", { staticClass: "text_209" }, [
                    _vm._v(_vm._s(_vm.pageText.text_209)),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text_210" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.pageText.text_210) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text_211" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.pageText.text_211) +
                        "\n            "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "term_footer" }, [
              _c("p", { staticClass: "text_212" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.pageText.text_212) +
                    " " +
                    _vm._s(_vm.termsData.lastUpdateDate) +
                    "\n        "
                ),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isSubdomainPL
      ? _c("div", [
          _c("div", { staticClass: "terms-conditions-head" }, [
            _c("h1", { staticClass: "text_1" }, [
              _vm._v(_vm._s(_vm.pageText.text_1)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "terms-condition-container" }, [
            _c("div", { staticClass: "privacy-policy-container" }, [
              _c("h1", { staticClass: "text_2" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_2) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "text_3" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_3) + "\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "one_line_col" }, [
              _c("ol", { attrs: { type: "1" } }, [
                _c("li", { staticClass: "text_4" }, [
                  _vm._v(_vm._s(_vm.pageText.text_4)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_5" }, [
                  _vm._v(_vm._s(_vm.pageText.text_5)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_6" }, [
                  _vm._v(_vm._s(_vm.pageText.text_6)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_7" }, [
                  _vm._v(_vm._s(_vm.pageText.text_7)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_8" }, [
                  _vm._v(_vm._s(_vm.pageText.text_8)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "one_line_col" }, [
              _c("h1", { staticClass: "text_9" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_9) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("h2", { staticClass: "text_10" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_10) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text_11" }, [
                _vm._v(_vm._s(_vm.pageText.text_11)),
              ]),
              _vm._v(" "),
              _c("ol", { attrs: { type: "1" } }, [
                _c("li", { staticClass: "text_12" }, [
                  _vm._v(_vm._s(_vm.pageText.text_12)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_13" }, [
                  _vm._v(_vm._s(_vm.pageText.text_13)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_14" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.pageText.text_14) +
                      "\n            "
                  ),
                  _c("ol", { attrs: { type: "a" } }, [
                    _c("li", { staticClass: "text_15" }, [
                      _vm._v(_vm._s(_vm.pageText.text_15)),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "text_16" }, [
                      _vm._v(_vm._s(_vm.pageText.text_16)),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "text_17" }, [
                      _vm._v(_vm._s(_vm.pageText.text_17)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_18" }, [
                  _vm._v(_vm._s(_vm.pageText.text_18)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_19" }, [
                  _vm._v(_vm._s(_vm.pageText.text_19)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_20" }, [
                  _vm._v(_vm._s(_vm.pageText.text_20)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_21" }, [
                  _vm._v(_vm._s(_vm.pageText.text_21)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_22" }, [
                  _vm._v(_vm._s(_vm.pageText.text_22)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_23" }, [
                  _vm._v(_vm._s(_vm.pageText.text_23)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "one_line_col" }, [
              _c("h1", { staticClass: "text_24" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_24) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("h2", { staticClass: "text_25" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_25) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("ol", { attrs: { type: "1" } }, [
                _c("li", { staticClass: "text_26" }, [
                  _vm._v(_vm._s(_vm.pageText.text_26)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_27" }, [
                  _vm._v(_vm._s(_vm.pageText.text_27)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_28" }, [
                  _vm._v(_vm._s(_vm.pageText.text_28)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_29" }, [
                  _vm._v(_vm._s(_vm.pageText.text_29)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_30" }, [
                  _vm._v(_vm._s(_vm.pageText.text_30)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_31" }, [
                  _vm._v(_vm._s(_vm.pageText.text_31)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_32" }, [
                  _vm._v(_vm._s(_vm.pageText.text_32)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_33" }, [
                  _vm._v(_vm._s(_vm.pageText.text_33)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_34" }, [
                  _vm._v(_vm._s(_vm.pageText.text_34)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_35" }, [
                  _vm._v(_vm._s(_vm.pageText.text_35)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "one_line_col" }, [
              _c("h1", { staticClass: "text_36" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_36) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("h2", { staticClass: "text_37" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_37) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("ol", { attrs: { type: "1" } }, [
                _c("li", { staticClass: "text_38" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.pageText.text_38) +
                      "\n            "
                  ),
                  _c("ol", { attrs: { type: "a" } }, [
                    _c("li", { staticClass: "text_39" }, [
                      _vm._v(_vm._s(_vm.pageText.text_39)),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "text_40" }, [
                      _vm._v(_vm._s(_vm.pageText.text_40)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_41" }, [
                  _vm._v(_vm._s(_vm.pageText.text_41)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_42" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.pageText.text_42) +
                      "\n            "
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c("p", { staticClass: "text_43" }, [
                    _vm._v(_vm._s(_vm.pageText.text_43)),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("p", { staticClass: "text_44" }, [
                    _vm._v(_vm._s(_vm.pageText.text_44)),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("p", { staticClass: "text_45" }, [
                    _vm._v(_vm._s(_vm.pageText.text_45)),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("p", { staticClass: "text_46" }, [
                    _vm._v(_vm._s(_vm.pageText.text_46)),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_47" }, [
                  _vm._v(_vm._s(_vm.pageText.text_47)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_48" }, [
                  _vm._v(_vm._s(_vm.pageText.text_48)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_49" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.pageText.text_49) +
                      "\n            "
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c("p", { staticClass: "text_50" }, [
                    _vm._v(_vm._s(_vm.pageText.text_50)),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "one_line_col" }, [
              _c("h1", { staticClass: "text_51" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_51) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("h2", { staticClass: "text_52" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_52) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("ol", { attrs: { type: "1" } }, [
                _c("li", { staticClass: "text_53" }, [
                  _vm._v(_vm._s(_vm.pageText.text_53)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_54" }, [
                  _vm._v(_vm._s(_vm.pageText.text_54)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_55" }, [
                  _vm._v(_vm._s(_vm.pageText.text_55)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "one_line_col" }, [
              _c("h1", { staticClass: "text_56" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_56) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("h2", { staticClass: "text_57" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_57) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("ol", { attrs: { type: "1" } }, [
                _c("li", { staticClass: "text_58" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.pageText.text_58) +
                      "\n            "
                  ),
                  _c("ol", { attrs: { type: "a" } }, [
                    _c("li", { staticClass: "text_59" }, [
                      _vm._v(_vm._s(_vm.pageText.text_59)),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "text_60" }, [
                      _vm._v(_vm._s(_vm.pageText.text_60)),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "text_61" }, [
                      _vm._v(_vm._s(_vm.pageText.text_61)),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "text_62" }, [
                      _vm._v(_vm._s(_vm.pageText.text_62)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_63" }, [
                  _vm._v(_vm._s(_vm.pageText.text_63)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_64" }, [
                  _vm._v(_vm._s(_vm.pageText.text_64)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_65" }, [
                  _vm._v(_vm._s(_vm.pageText.text_65)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_66" }, [
                  _vm._v(_vm._s(_vm.pageText.text_66)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "one_line_col" }, [
              _c("h1", { staticClass: "text_67" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_67) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("h2", { staticClass: "text_68" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_68) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("ol", { attrs: { type: "1" } }, [
                _c("li", { staticClass: "text_69" }, [
                  _vm._v(_vm._s(_vm.pageText.text_69)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_70" }, [
                  _vm._v(_vm._s(_vm.pageText.text_70)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_71" }, [
                  _vm._v(_vm._s(_vm.pageText.text_71)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_72" }, [
                  _vm._v(_vm._s(_vm.pageText.text_72)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_73" }, [
                  _vm._v(_vm._s(_vm.pageText.text_73)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_74" }, [
                  _vm._v(_vm._s(_vm.pageText.text_74)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_75" }, [
                  _vm._v(_vm._s(_vm.pageText.text_75)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "one_line_col" }, [
              _c("h1", { staticClass: "text_76" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_76) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("h2", { staticClass: "text_77" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_77) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("ol", { attrs: { type: "1" } }, [
                _c("li", { staticClass: "text_78" }, [
                  _vm._v(_vm._s(_vm.pageText.text_78)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_79" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.pageText.text_79) +
                      "\n            "
                  ),
                  _c("ol", { attrs: { type: "a" } }, [
                    _c("li", { staticClass: "text_80" }, [
                      _vm._v(_vm._s(_vm.pageText.text_80)),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "text_81" }, [
                      _vm._v(_vm._s(_vm.pageText.text_81)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_82" }, [
                  _vm._v(_vm._s(_vm.pageText.text_82)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_83" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.pageText.text_83) +
                      "\n            "
                  ),
                  _c("ol", { attrs: { type: "a" } }, [
                    _c("li", { staticClass: "text_84" }, [
                      _vm._v(_vm._s(_vm.pageText.text_84)),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "text_85" }, [
                      _vm._v(_vm._s(_vm.pageText.text_85)),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "text_86" }, [
                      _vm._v(_vm._s(_vm.pageText.text_86)),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "text_87" }, [
                      _vm._v(_vm._s(_vm.pageText.text_87)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_88" }, [
                  _vm._v(_vm._s(_vm.pageText.text_88)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_89" }, [
                  _vm._v(_vm._s(_vm.pageText.text_89)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_90" }, [
                  _vm._v(_vm._s(_vm.pageText.text_90)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_91" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.pageText.text_91) +
                      "\n            "
                  ),
                  _c("ol", { attrs: { type: "a" } }, [
                    _c("li", { staticClass: "text_92" }, [
                      _vm._v(_vm._s(_vm.pageText.text_92)),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "text_93" }, [
                      _vm._v(_vm._s(_vm.pageText.text_93)),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "one_line_col" }, [
              _c("h1", { staticClass: "text_94" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_94) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("h2", { staticClass: "text_95" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_95) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("ol", { attrs: { type: "1" } }, [
                _c("li", { staticClass: "text_96" }, [
                  _vm._v(_vm._s(_vm.pageText.text_96)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_97" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.pageText.text_97) +
                      "\n            "
                  ),
                  _c("ol", { attrs: { type: "a" } }, [
                    _c("li", { staticClass: "text_98" }, [
                      _vm._v(_vm._s(_vm.pageText.text_99)),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "text_99" }, [
                      _vm._v(_vm._s(_vm.pageText.text_99)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_100" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.pageText.text_100) +
                      "\n            "
                  ),
                  _c("ol", { attrs: { type: "a" } }, [
                    _c("li", { staticClass: "text_101" }, [
                      _vm._v(_vm._s(_vm.pageText.text_101)),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "text_102" }, [
                      _vm._v(_vm._s(_vm.pageText.text_102)),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "text_103" }, [
                      _vm._v(_vm._s(_vm.pageText.text_103)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_104" }, [
                  _vm._v(_vm._s(_vm.pageText.text_104)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_105" }, [
                  _vm._v(_vm._s(_vm.pageText.text_105)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_106" }, [
                  _vm._v(_vm._s(_vm.pageText.text_106)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_107" }, [
                  _vm._v(_vm._s(_vm.pageText.text_107)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "one_line_col" }, [
              _c("h1", { staticClass: "text_108" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_108) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("h2", { staticClass: "text_109" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.pageText.text_109) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("ol", { attrs: { type: "1" } }, [
                _c("li", { staticClass: "text_110" }, [
                  _vm._v(_vm._s(_vm.pageText.text_110)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_111" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.pageText.text_111) +
                      "\n            "
                  ),
                  _c("ol", { attrs: { type: "a" } }, [
                    _c("li", { staticClass: "text_112" }, [
                      _vm._v(_vm._s(_vm.pageText.text_112)),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "text_113" }, [
                      _vm._v(_vm._s(_vm.pageText.text_113)),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "text_114" }, [
                      _vm._v(_vm._s(_vm.pageText.text_114)),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "text_115" }, [
                      _vm._v(_vm._s(_vm.pageText.text_115)),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "text_116" }, [
                      _vm._v(_vm._s(_vm.pageText.text_116)),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "text_117" }, [
                      _vm._v(_vm._s(_vm.pageText.text_117)),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "text_118" }, [
                      _vm._v(_vm._s(_vm.pageText.text_118)),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "text_119" }, [
                      _vm._v(_vm._s(_vm.pageText.text_119)),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "text_120" }, [
                      _vm._v(_vm._s(_vm.pageText.text_120)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_121" }, [
                  _vm._v(_vm._s(_vm.pageText.text_121)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_122" }, [
                  _vm._v(_vm._s(_vm.pageText.text_122)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_123" }, [
                  _vm._v(_vm._s(_vm.pageText.text_123)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_124" }, [
                  _vm._v(_vm._s(_vm.pageText.text_124)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_125" }, [
                  _vm._v(_vm._s(_vm.pageText.text_125)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_126" }, [
                  _vm._v(_vm._s(_vm.pageText.text_126)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_127" }, [
                  _vm._v(_vm._s(_vm.pageText.text_127)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_128" }, [
                  _vm._v(_vm._s(_vm.pageText.text_128)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_129" }, [
                  _vm._v(_vm._s(_vm.pageText.text_129)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_130" }, [
                  _vm._v(_vm._s(_vm.pageText.text_130)),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "text_131" }, [
                  _vm._v(_vm._s(_vm.pageText.text_131)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "one_line_col" }, [
              _c("p", { staticClass: "text_132" }, [
                _vm._v(_vm._s(_vm.pageText.text_132)),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("p", { staticClass: "text_133" }, [
                _vm._v(_vm._s(_vm.pageText.text_133)),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("p", { staticClass: "text_134" }, [
                _vm._v(_vm._s(_vm.pageText.text_134)),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("p", { staticClass: "text_135" }, [
                _vm._v(_vm._s(_vm.pageText.text_135)),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("p", { staticClass: "text_136" }, [
                _vm._v(_vm._s(_vm.pageText.text_136)),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("p", { staticClass: "text_137" }, [
                _vm._v(_vm._s(_vm.pageText.text_137)),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("p", { staticClass: "text_138" }, [
                _vm._v(_vm._s(_vm.pageText.text_138)),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("p", { staticClass: "text_139" }, [
                _vm._v(_vm._s(_vm.pageText.text_139)),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("p", { staticClass: "text_140" }, [
                _vm._v(_vm._s(_vm.pageText.text_140)),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("u", [
        _c(
          "a",
          {
            attrs: { href: "http://n.vr-games.biz/za/tnc-xr-academy?offer=1" },
          },
          [_vm._v("http://n.vr-games.biz/za/tnc-xr-academy?offer=1")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6yRso" } }, [
          _vm._v('"Plant Cell"'),
        ]),
        _vm._v(" by t.sullivan is\n            licensed under\n            "),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [_vm._v("\n              Creative Commons Attribution\n            ")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6YuAv" } }, [
          _vm._v('"Animal Cell"'),
        ]),
        _vm._v(
          " by Roopangi\n            Mohta is licensed under\n            "
        ),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [_vm._v("\n              Creative Commons Attribution\n            ")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/67RFV" } }, [
          _vm._v('"Blue Whale - Textured"'),
        ]),
        _vm._v(" by\n            Bohdan Lvov is licensed under\n            "),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [_vm._v("\n              Creative Commons Attribution\n            ")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6RCAu" } }, [
          _vm._v('"Model 58A - House gecko"'),
        ]),
        _vm._v(
          " by\n            DigitalLife3D is licensed under\n            "
        ),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [_vm._v("\n              Creative Commons Attribution\n            ")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/67DEv" } }, [
          _vm._v(
            '\n              "Model 20 - Panamanian Golden Frog"\n            '
          ),
        ]),
        _vm._v(
          "\n            by DigitalLife3D is licensed under\n            "
        ),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [_vm._v("\n              Creative Commons Attribution\n            ")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6VAJB" } }, [
          _vm._v(
            '\n              "Blue Ringed Octopus - Inktober Day 1"\n            '
          ),
        ]),
        _vm._v("\n            by lesliestowe is licensed under\n            "),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [_vm._v("\n              Creative Commons Attribution\n            ")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/66T8F" } }, [
          _vm._v(' "Porcupine Fish - 6082" '),
        ]),
        _vm._v(" by\n            osuecampus is licensed under\n            "),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [_vm._v("\n              Creative Commons Attribution\n            ")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/o6rQR" } }, [
          _vm._v(' "Château de Bonaguil" '),
        ]),
        _vm._v(" by\n            pierre391 is licensed under\n            "),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [_vm._v("\n              Creative Commons Attribution\n            ")]
        ),
        _vm._v("\n            .\n          "),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6QXqU" } }, [
          _vm._v(' "Stonehenge England - VR" '),
        ]),
        _vm._v(" by\n            GSXNet is licensed under\n            "),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [_vm._v("\n              Creative Commons Attribution\n            ")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6ZPtG" } }, [
          _vm._v(' "Machu Picchu" '),
        ]),
        _vm._v(" by Théo\n            Derory is licensed under\n            "),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [_vm._v("\n              Creative Commons Attribution\n            ")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/SrBn" } }, [
          _vm._v(' "Hard won victory-FT17 Tank" '),
        ]),
        _vm._v("\n            by JonTan is licensed under\n            "),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [_vm._v("\n              Creative Commons Attribution\n            ")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6ZrSS" } }, [
          _vm._v(
            '\n              "assignment1: the flying circus"\n            '
          ),
        ]),
        _vm._v(
          "\n            by MichielBaumann is licensed under\n            "
        ),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [_vm._v("\n              Creative Commons Attribution\n            ")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6WJxx" } }, [
          _vm._v(
            '\n              "Relief of eagle-headed demon"\n            '
          ),
        ]),
        _vm._v(
          "\n            by Fitzwilliam Museum is licensed under\n            "
        ),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [_vm._v("\n              Creative Commons Attribution\n            ")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6wWPn" } }, [
          _vm._v(' "Tiglath-Pileser III" '),
        ]),
        _vm._v(" by\n            danielpett is licensed under\n            "),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [_vm._v("\n              Creative Commons Attribution\n            ")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6XWIL" } }, [
          _vm._v(' "Byzantine-Medieval Papyrus" '),
        ]),
        _vm._v("\n            by Thomas Flynn is licensed under\n            "),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [_vm._v("\n              Creative Commons Attribution\n            ")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6ZOXZ" } }, [
          _vm._v(
            '\n              "Palmyrene Soldiers in Roman Ranks -Trajan Column"\n            '
          ),
        ]),
        _vm._v(
          "\n            by Gustaw Mackay is licensed under Creative Commons Attribution.\n          "
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/P9CE)" } }, [
          _vm._v(
            '\n              "Dancing Ganesha, Brit. Mus. / North India"\n            '
          ),
        ]),
        _vm._v("\n            by Thomas Flynn is licensed under\n            "),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [_vm._v("\n              Creative Commons Attribution\n            ")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6YwPH" } }, [
          _vm._v(
            '\n              "The Thinker by Auguste Rodin"\n            '
          ),
        ]),
        _vm._v("\n            by Rigsters is licensed under\n            "),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [_vm._v("\n              Creative Commons Attribution\n            ")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          '\n            "Laocoon and His Sons" () by Rigsters is licensed under Creative\n            Commons Attribution.\n          '
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/F9Ho)" } }, [
          _vm._v(' "Venus de Willendorf" '),
        ]),
        _vm._v(
          " by\n            Raúl Balsera Moraño is licensed under\n            "
        ),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [_vm._v("\n              Creative Commons Attribution\n            ")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6nUrC" } }, [
          _vm._v(' "Salvador Dali - Mae West" '),
        ]),
        _vm._v("\n            by Deftroy is licensed under\n            "),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [_vm._v("\n              Creative Commons Attribution\n            ")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6RsFV" } }, [
          _vm._v(' "Mona Lisa Painting" '),
        ]),
        _vm._v(" by 3Dee\n            is licensed under\n            "),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [_vm._v("\n              Creative Commons Attribution\n            ")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6YvGQ" } }, [
          _vm._v('\n              "Dali,The Persistence of Memory"'),
        ]),
        _vm._v("\n            by arloopa is licensed under\n            "),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [_vm._v("\n              Creative Commons Attribution\n            ")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6zTJx" } }, [
          _vm._v(
            '\n              "De sterrennacht Nit estelada The Starry Night" '
          ),
        ]),
        _vm._v("by dimoni is licensed under\n            "),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [_vm._v("\n              Creative Commons Attribution\n            ")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6TB7s" } }, [
          _vm._v(
            '\n              "Wanderer above the sea of fog"\n            '
          ),
        ]),
        _vm._v("\n            by betocarrillo is licensed under\n            "),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by-sa/4.0/" } },
          [_vm._v("\n              Creative Commons Attribution-ShareAlike.")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6Snqw" } }, [
          _vm._v(' "The birth of a star" '),
        ]),
        _vm._v(
          " by\n            Salvatore Orlando is licensed under\n            "
        ),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [_vm._v("\n              Creative Commons Attribution.")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6yGSx" } }, [
          _vm._v(' "Sun" '),
        ]),
        _vm._v(
          " by SebastianSosnowski\n            is licensed under\n            "
        ),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [
            _vm._v(
              "\n              Creative Commons Attribution.\n            "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/PCYW)" } }, [
          _vm._v(' "Blackhole" '),
        ]),
        _vm._v(" by rubykamen is\n            licensed under\n            "),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [
            _vm._v(
              "\n              Creative Commons Attribution.\n            "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6CSSo" } }, [
          _vm._v(
            '\n              "Nexus" NASA 1960s super heavy rocket project"\n            '
          ),
        ]),
        _vm._v(
          "\n            by SebastianSosnowski is licensed under\n            "
        ),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [
            _vm._v(
              "\n              Creative Commons Attribution.\n            "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6RQpN" } }, [
          _vm._v(
            '\n              "LUCY | NASA Space Probe | Free Download"\n            '
          ),
        ]),
        _vm._v(
          "\n            by murilo.kleine is licensed under\n            "
        ),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [
            _vm._v(
              "\n              Creative Commons Attribution.\n            "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6U8BH" } }, [
          _vm._v(' "Earth" '),
        ]),
        _vm._v(" by PatelDev is\n            licensed under\n            "),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [
            _vm._v(
              "\n              Creative Commons Attribution.\n            "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6AOGX" } }, [
          _vm._v(' "Mercury" '),
        ]),
        _vm._v(
          " by Anthony Yanez\n            is licensed under\n            "
        ),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [
            _vm._v(
              "\n              Creative Commons Attribution.\n            "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6AONM" } }, [
          _vm._v(' "Saturn" '),
        ]),
        _vm._v("by Anthony Yanez is\n            licensed under\n            "),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [
            _vm._v(
              "\n              Creative Commons Attribution.\n            "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6TwFR" } }, [
          _vm._v(' "Venus" '),
        ]),
        _vm._v(" by Akshat is\n            licensed under\n            "),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [_vm._v("\n              Creative Commons Attribution.")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6AOOL" } }, [
          _vm._v(' "Uranus" '),
        ]),
        _vm._v(
          " by Anthony Yanez is\n            licensed under\n            "
        ),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [_vm._v("\n              Creative Commons Attribution.")]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6AOP9" } }, [
          _vm._v(' "Neptune" '),
        ]),
        _vm._v("by Anthony Yanez is\n            licensed under\n            "),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [
            _vm._v(
              "\n              Creative Commons Attribution.\n            "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6XNzD" } }, [
          _vm._v(
            '\n              "Jabal al-Lawz / Maqla - Mount Sinai in Arabia" '
          ),
        ]),
        _vm._v("by Nate Loper 🗺️ ⛏🏺 is licensed under\n            "),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [
            _vm._v(
              "\n              Creative Commons Attribution.\n            "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6zW68" } }, [
          _vm._v(' "Jupiter" () '),
        ]),
        _vm._v(
          "\n            by murilo.kleine is licensed under\n            "
        ),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by/4.0/" } },
          [
            _vm._v(
              "\n              Creative Commons Attribution.\n            "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("a", { attrs: { href: "https://skfb.ly/6WMUR" } }, [
          _vm._v(' "Saturn V - NASA" () '),
        ]),
        _vm._v(
          "\n            by Stanley Creative is licensed under\n            "
        ),
        _c(
          "a",
          { attrs: { href: "http://creativecommons.org/licenses/by-nd/4.0/" } },
          [
            _vm._v(
              "\n              Creative Commons Attribution-NoDerivs.\n            "
            ),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }